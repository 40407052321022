
.contact-me {
    padding: 120px 0 0;
    margin-bottom: -17px
}

.contuict-me-main {
    display: flex;
    justify-content: space-between;
    padding: 60px 78px;
    background-color: #f8fbff;
    border: 1px solid #dfeaf9;
    box-shadow: 0 30px 50px rgba(14,23,57,.04);
    border-radius: 0 15px 15px 0;
    position: relative
}

.contuict-me-main::after {
    position: absolute;
    content: "";
    top: 0;
    left: 50%;
    width: 1px;
    height: 100%;
    background-color: #dfeaf9
}

.contuict-me-main .contact-me-item {
    display: flex
}

.contuict-me-main .contact-me-item .contact-me-inner {
    display: flex;
    gap: 34px
}

.contuict-me-main .contact-me-item .contact-me-inner .text h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 150%
}

.contuict-me-main .contact-me-item .contact-me-inner .text p {
    font-weight: 400;
    font-size: 20px;
    line-height: 35px
}

.contuict-me-main .contact-me-item .contact-me-inner .text h5 {
    font-weight: 600;
    font-size: 24px;
    color: #04091e;
    line-height: 150%
}
