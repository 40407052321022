body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.product-overlay a{
  opacity: 0;
}

.product-overlay:hover a{
  opacity: 1;
  color: #111;
  padding: 10px 20px;
  border-radius: 10px;
  background-color: #fff;
}

