
.h-1-blog {
    background: url(/public/img/blog/h-1-blog-bg.png) no-repeat center/cover;
    padding: 105px 0 120px
}

.h-1-blog .b-h-1-mt {
    margin-top: 25px
}
.icon{
    display: flex;
    justify-content: center;
    align-items: center;
}

.h-1-blog .b-h-1-mt-2 {
    margin-top: 53px
}

.h-1-blog .blog-head {
    position: relative;
    z-index: 1
}

.h-1-blog .blog-head h2 {
    font-weight: 700;
    font-size: 48px;
    line-height: 140%;
    color: #04091e;
    text-align: center;
    padding: 0 290px
}

.h-1-blog .blog-vic {
    position: absolute;
    bottom: 3px;
    right: 510px;
    z-index: -1
}

.h-1-blog .h-b-top {
    margin-top: 40px
}

.h-1-blog .h-1-mt {
    margin-top: 30px
}

.h-1-blog .blog-item {
    padding: 20px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 5px 6px 60px rgba(17,29,59,.04);
    margin: 0 7px
}

.h-1-blog .blog-item .blog-img {
    overflow: hidden;
    border-radius: 8px
}

.h-1-blog .blog-item .blog-img img {
    width: 100%;
    transition: all linear .5s;
    position: relative
}

.h-1-blog .blog-item .blog-inner {
    padding-top: 24px
}

.h-1-blog .blog-item .blog-inner .blog-inner-btn {
    display: flex;
    gap: 8px
}

.h-1-blog .blog-item .blog-inner .blog-inner-btn a {
    padding: 0 8px;
    display: inline-block;
    background-color: #f4f4f4;
    border-radius: 6px;
    font-weight: 400;
    font-size: 18px;
    line-height: 152%;
    color: #747681
}

.h-1-blog .blog-item .blog-inner .blog-inner-text {
    padding-top: 8px
}

.h-1-blog .blog-item .blog-inner .blog-inner-text a {
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    color: #04091e;
    transition: all linear .5s
}

.h-1-blog .blog-item .blog-inner .blog-btn {
    padding-top: 8px
}

.h-1-blog .blog-item .blog-inner .blog-btn a {
    font-weight: 400;
    font-size: 18px;
    color: #747681;
    padding-top: 8px
}

.h-1-blog .blog-item:hover.blog-item .blog-img img {
    transform: scale(1.1)
}

.h-1-blog .blog-item:hover .blog-inner .blog-inner-text a {
    transform: scale(1.1);
    text-decoration-line: underline
}

.h-1-blog .h-1-blog-btn {
    display: flex;
    justify-content: end;
    margin-top: 60px
}

.h-1-blog .pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    gap: 8px
}

.h-1-blog .page-link {
    padding: 0;
    font-size: 16px;
    font-weight: 500;
    height: 40px;
    width: 40px;
    background-color: #fff;
    border: 1px solid #f1f1f1;
    border-radius: 8px;
    text-align: center;
    line-height: 40px
}

.h-1-blog .page-link i {
    color: #04091e
}

.h-1-blog .page-link {
    color: #747681
}

.h-1-blog .page-link:focus {
    z-index: 3;
    color: #0a58ca;
    background-color: #e9ecef;
    outline: 0;
    box-shadow: 0 0 0 .25rem transparent
}

.h-1-blog .page-item:first-child .page-link {
    border-radius: 8px
}

.h-1-blog .page-item:last-child .page-link {
    border-radius: 8px
}

.b-pl {
    padding-left: 20px
}

.sarch-box {
    box-shadow: 5px 6px 60px rgba(17,29,59,.04);
    border-radius: 4px;
    border: 1px solid #f4f4f4;
    background-color: #fff;
    padding: 30px
}

.sarch-box .sarch-box-head h2 {
    font-weight: 500;
    font-size: 18px;
    line-height: 152%;
    color: #04091e;
    padding-bottom: 13px
}

.sarch-box .search-main {
    width: 100%;
    position: relative;
    display: flex
}

.sarch-box .search-input {
    width: 100%;
    background-color: #f5f5f5;
    border: transparent;
    padding: 15px 20px 18px;
    font-weight: 500;
    font-size: 18px;
    line-height: 152%;
    font-family: work sans,sans-serif
}

.sarch-box .search-button {
    border: transparent;
    background-color: #f5f5f5;
    padding: 0 20px 0 0;
    color: #0a58ca;
    font-size: 25px
}

.latest-box {
    box-shadow: 5px 6px 60px rgba(17,29,59,.04);
    border-radius: 4px;
    padding: 30px 18px 30px 30px;
    background-color: #fff;
    margin-top: 30px
}

.latest-box .latest-box-head h2 {
    font-weight: 600;
    font-size: 18px;
    line-height: 152%;
    color: #04091e;
    padding-bottom: 19px
}

.latest-box .latest-box-item .latest-box-inner {
    display: flex;
    gap: 18px;
    align-items: center;
    margin-top: 30px
}

.latest-box .latest-box-item .latest-box-inner:first-child {
    margin-top: 0
}

.latest-box .latest-box-item .latest-box-inner .latest-box-text a {
    font-weight: 600;
    font-size: 18px;
    line-height: 152%;
    color: #04091e;
    transition: all .5s
}

.latest-box .latest-box-item .latest-box-inner .latest-box-text a:hover {
    color: #0a58ca
}

.latest-box .latest-box-item .latest-box-inner .latest-box-text p {
    padding-top: 9px;
    font-weight: 400;
    font-size: 18px;
    line-height: 152%;
    color: #747681
}

.latest-box .latest-box-item .latest-box-inner .latest-box-text p span {
    padding-right: 12px
}

.categories {
    margin-top: 30px;
    padding: 30px;
    box-shadow: 5px 6px 60px rgba(17,29,59,.04);
    border-radius: 4px;
    border: 1px solid #f4f4f4;
    background-color: #fff
}

.categories .categories-box-head h2 {
    font-weight: 600;
    font-size: 18px;
    color: #04091e
}

.categories .categories-item .categories-item-text a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 18px;
    line-height: 240%;
    color: #747681;
    transition: all .5s
}

.categories .categories-item .categories-item-text a:hover {
    color: #0a58ca
}

.tag {
    padding: 30px 24px 30px 30px;
    box-shadow: 5px 6px 60px rgba(17,29,59,.04);
    border-radius: 4px;
    border: 1px solid #f4f4f4;
    background-color: #fff;
    margin-top: 28px
}

.tag .tag-head h2 {
    font-weight: 600;
    font-size: 18px;
    line-height: 160%;
    color: #04091e
}

.tag .tag-btn {
    display: flex;
    flex-wrap: wrap;
    gap: 14px;
    margin-top: 11px
}

.tag .tag-btn a {
    border: 1px solid #f4f4f4;
    border-radius: 5px;
    padding: 3px 12px;
    display: inline-block;
    font-weight: 400;
    font-size: 16px;
    line-height: 152%;
    color: #747681;
    margin-bottom: 6px;
    transition: all .5s
}

.tag .tag-btn a:hover {
    background-color: #0a58ca;
    color: #fff
}

.tag .tag-btn .tag-active {
    background-color: #0a58ca;
    color: #fff
}

.follow-us {
    margin-top: 30px;
    padding: 28px 73px 28px 29px;
    box-shadow: 5px 6px 60px rgba(17,29,59,.04);
    border-radius: 4px;
    background-color: #fff
}

.follow-us .follow-us-head h2 {
    font-weight: 600;
    font-size: 18px;
    line-height: 152%;
    color: #04091e
}

.follow-us .follow-icon {
    display: flex;
    gap: 32px;
    margin-top: 10px
}

.follow-us .follow-icon .icon {
    height: 43px;
    width: 43px;
    background-color: rgba(38,98,250,.1);
    border-radius: 50px;
    text-align: center;
    line-height: 40px;
    transition: all .5s
}

.follow-us .follow-icon .icon span svg {
    fill: currentColor;
    color: #2662fa;
    transition: all .5s
}

.follow-us .follow-icon .icon:hover {
    background-color: #2662fa
}

.follow-us .follow-icon .icon:hover span svg {
    color: #fdeaee
}

.services-details {
    padding: 120px 0 0
}

.article {
    margin-top: 30px;
    border-bottom: 1px solid rgba(116,118,129,.3019607843);
    padding-bottom: 10px
}

.services-details-article-text span {
    font-weight: 600;
    font-size: 18px;
    line-height: 170%;
    color: #272931
}

.services-details-article-text p {
    font-weight: 400;
    font-size: 18px;
    line-height: 152%;
    color: #747681
}

.services-details-article-text-two span {
    color: #fff
}

.services-details-article-icon {
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: end
}

.services-details-article-icon .text p {
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    color: rgba(18,20,29,.6)
}

.services-details-article-icon .icon {
    display: flex;
    gap: 16px
}

.services-details-article-icon .icon i {
    height: 40px;
    width: 40px;
    background-color: #04091e;
    box-shadow: 7px 59.0047px 60px -31.4692px rgba(18,20,29,.2);
    color: #fff;
    font-size: 20px;
    line-height: 40px;
    text-align: center;
    border-radius: 50px;
    transition: all .5s
}

.services-details-article-icon .icon i:hover {
    background-color: #2662fa
}

.services-details-article-icon-two {
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: end
}

.services-details-article-icon-two .text p {
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    color: #fff
}

.services-details-article-icon-two .icon i {
    background-color: #747681;
    box-shadow: 7px 59.0047px 60px -31.4692px rgba(18,20,29,.2);
    color: #fff
}

.services-details-article-icon-two .icon i:hover {
    background-color: #daed1a;
    color: #04091e
}

.research-top {
    margin-top: 40px
}

.research-img {
    margin: 0 7px
}

.website {
    margin-top: 23px
}

.website .website-text h2 {
    font-weight: 600;
    font-size: 48px;
    line-height: 140%;
    color: #04091e;
    padding-bottom: 20px
}

.website .website-text span {
    font-weight: 400;
    font-size: 18px;
    line-height: 152%;
    color: #747681
}

.website .website-text p {
    font-weight: 400;
    font-size: 18px;
    line-height: 152%;
    color: #747681;
    padding-top: 20px
}

.website {
    margin-top: 40px
}

.website .website-text-two h2 {
    color: #fff
}

.website .website-text-two span {
    font-weight: 400;
    font-size: 18px;
    line-height: 152%;
    color: #747681
}

.website .website-text-two p {
    font-weight: 400;
    font-size: 18px;
    line-height: 152%;
    color: #747681;
    padding-top: 30px
}

.research-ml {
    margin-right: 6px
}

.research-ml:last-child {
    margin-right: 0
}

.research {
    padding-top: 3px
}

.research h2 {
    font-weight: 600;
    font-size: 32px;
    line-height: 140%;
    color: #04091e;
    padding-top: 20px
}

.research p {
    font-weight: 400;
    font-size: 18px;
    line-height: 152%;
    color: #747681;
    padding-top: 20px
}

.research-two {
    padding-top: 3px
}

.research-two h2 {
    font-weight: 600;
    font-size: 32px;
    line-height: 140%;
    color: #fff;
    padding-top: 20px
}

.research-top-text {
    margin-top: 40px
}

.research-top-text ul {
    list-style: disc;
    padding-left: 1rem;
    color: #747681
}

.research-top-text ul li {
    font-weight: 400;
    font-size: 20px;
    line-height: 152%
}
.query-img {
    /* background: url(../assets/image/others/banner/query-bg.png) no-repeat center/cover; */
    padding: 25px 47px 23px 48px;
    border-radius: 18px;
    margin-top: 50px
}

.query-img .query h2 {
    font-weight: 600;
    font-size: 48px;
    line-height: 140%;
    color: #fff
}

.query-img .query-btn {
    text-align: end;
    line-height: 0
}

.query-img .query-btn .btn-style-1 a {
    background-color: rgba(255,255,255,.1)
}

.query-img-two {
    /* background: url(../assets/image/others/banner/query-bg-2.png) no-repeat center/cover; */
    padding: 25px 47px 23px 48px;
    border-radius: 18px;
    margin-top: 50px
}

.query-img-two .query h2 {
    color: #04091e
}

.query-img-two .query-btn {
    text-align: end;
    line-height: 0
}

.query-img-two .query-btn .btn-style-1 a {
    background-color: #04091e;
    color: #fff
}


.comment {
    padding: 50px 0 120px
}

.comment .comment-head h4 {
    font-weight: 600;
    font-size: 24px;
    color: #111;
    padding-bottom: 10px
}

.comment .comment-item .comment-inner {
    padding: 30px 27px 28px 30px;
    border: 1px solid #eceaff;
    border-radius: 4px;
    background-color: #f7f7fd;
    display: flex;
    gap: 24px;
    margin-bottom: 30px
}

.comment .comment-item .comment-inner:last-child {
    margin-bottom: 0
}

.comment .comment-item .comment-inner .comment-text .text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 5px
}

.comment .comment-item .comment-inner .comment-text .text h4 {
    font-weight: 600;
    font-size: 18px;
    color: #04091e;
    line-height: 152%
}

.comment .comment-item .comment-inner .comment-text .text span {
    font-weight: 400;
    font-size: 18px;
    color: #2662fa;
    line-height: 152%
}

.comment .comment-item .comment-inner .comment-text p {
    font-weight: 400;
    font-size: 18px;
    line-height: 152%;
    color: #747681;
    line-height: 152%
}

.comment .comment-item .comment-inner .comment-text a {
    font-weight: 400;
    font-size: 18px;
    color: #2662fa;
    line-height: 152%
}

.comment .comment-item .comment-inner-two {
    margin-left: 120px
}
