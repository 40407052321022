

.product {
    padding: 115px 0 120px
}

.product .h-1-blog-btn {
    display: flex;
    justify-content: end;
    margin-top: 60px
}

.product .pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    gap: 8px
}

.product .page-link {
    padding: 0;
    height: 40px;
    width: 40px;
    background-color: #fff;
    border: 1px solid #f1f1f1;
    border-radius: 8px;
    text-align: center;
    line-height: 40px
}

.product .page-link i {
    color: #04091e
}

.product .page-link {
    color: #747681
}

.product .page-link:focus {
    z-index: 3;
    color: #0a58ca;
    background-color: #e9ecef;
    outline: 0;
    box-shadow: 0 0 0 .25rem transparent
}

.product .page-item:first-child .page-link {
    border-radius: 8px
}

.product .page-item:last-child .page-link {
    border-radius: 8px
}

.product .padd {
    padding: 0 15px
}

.product .featured-top {
    margin-top: 61px
}

.product .product-mt {
    margin-top: 30px
}

.product .featured-item .featured-item-img {
    position: relative
}

.product .featured-item .featured-item-img img {
    width: 100%
}

.product .featured-item .featured-item-img:hover .featured-overlay {
    opacity: 1
}

.product .featured-item .featured-item-img .featured-overlay {
    transition: all .5s;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg,rgba(255,255,255,0) -24.52%,rgba(0,12,43,0.58) 72.76%,rgba(0,12,43,0.85) 100%);
    border-radius: 10px
}

.product .featured-item .featured-item-img .featured-overlay .featured-overlay-inner {
    padding: 0 45px;
    position: absolute;
    bottom: 39px
}

.product .featured-item .featured-item-img .featured-overlay .featured-overlay-inner .featured-overlay-text h3 {
    padding-right: 5px
}

.product .featured-item .featured-item-img .featured-overlay .featured-overlay-inner .featured-overlay-text h3 a {
    font-weight: 600;
    font-size: 32px;
    line-height: 160%;
    color: #fff
}

.product .featured-item .featured-item-img .featured-overlay .featured-overlay-inner .featured-overlay-icon {
    display: flex;
    gap: 10px;
    align-items: center
}

.product .featured-item .featured-item-img .featured-overlay .featured-overlay-inner .featured-overlay-icon .icon i {
    color: #ffce4c;
    border-radius: 2px
}

.product .featured-item .featured-item-img .featured-overlay .featured-overlay-inner .featured-overlay-icon .icon i:last-child {
    color: #c4c4c4
}

.product .featured-item .featured-item-img .featured-overlay .featured-overlay-inner .featured-overlay-icon .text span {
    font-weight: 400;
    font-size: 24px;
    color: #fff
}

.product .featured-item .featured-item-img .featured-overlay .featured-overlay-btm-text span {
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    color: #fff;
    padding-right: 10px
}

.product .featured-item .featured-item-img .featured-overlay .featured-overlay-btm-text span del {
    color: #c4c4c4;
    font-weight: 400
}

.product .featured-item .featured-item-img .featured-overlay .featured-overlay-btm-img {
    text-align: end;
    margin-top: -37px
}

.product-head {
    text-align: center;
    position: relative;
    z-index: 1
}

.product-head h2 {
    font-weight: 700;
    font-size: 48px;
    color: #04091e
}

.product-head .product-vic {
    position: absolute;
    top: 0;
    right: 377px;
    z-index: -1
}

.product-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 25px 16px 16px;
    border: 1px solid #f4f4f4;
    filter: drop-shadow(0px 9px 126px rgba(20,0,255,0.06));
    border-radius: 4px;
    margin-top: 45px
}

.product-box .product-sarch {
    display: flex;
    align-items: center;
    gap: 40px
}

.product-box .product-sarch .product-btn {
    display: flex
}

.product-box .product-sarch .product-btn .product-btn-left .p-b-text {
    width: 450px;
    height: 60px;
    background-color: rgba(38,98,250,.1176470588);
    border-radius: 4px;
    font-weight: 400;
    font-size: 18px;
    line-height: 152%;
    color: #747681;
    border: none;
    padding-left: 20px
}

.product-box .product-sarch .product-btn .product-btn-right a {
    width: 147px;
    height: 60px;
    background-color: #2662fa;
    border-radius: 4px;
    display: inline-block;
    font-weight: 400;
    font-size: 18px;
    line-height: 60px;
    color: #fff;
    text-align: center;
    border: .5px solid #2662fa;
    transition: all .5s
}

.product-box .product-sarch .product-btn .product-btn-right a:hover {
    background-color: #fff;
    color: #2662fa
}

.product-box .product-sarch .results p {
    font-weight: 400;
    font-size: 18px;
    color: #747681
}

.product-box .product-sarch .results p span {
    color: #04091e
}

.product-box .product-grid {
    display: flex;
    align-items: center;
    gap: 16px
}

.product-box .product-grid .icon-1 {
    width: 42px;
    height: 42px;
    background-color: #2662fa;
    border-radius: 4px;
    line-height: 42px;
    text-align: center
}

.product-box .product-grid .icon-1 span svg {
    fill: currentColor;
    color: #fff
}

.product-box .product-grid .icon {
    width: 42px;
    height: 42px;
    background-color: #f4f4f4;
    border-radius: 4px;
    line-height: 42px;
    text-align: center;
    transition: all .5s
}

.product-box .product-grid .icon:hover {
    background-color: #2662fa
}

.product-box .product-grid .icon:hover span svg {
    color: #fff
}

.product-box .product-grid .icon span svg {
    fill: currentColor;
    color: #747681;
    transition: all .5s
}

.featured {
    padding: 112px 0 120px
}

.featured .featured-head h4 {
    font-weight: 600;
    font-size: 24px;
    color: #2662fa;
    position: relative;
    margin-left: 70px
}

.featured .featured-head h4::before {
    position: absolute;
    content: "";
    top: 12px;
    left: -65px;
    width: 46px;
    height: 4px;
    background-color: #2662fa
}

.featured .featured-head h2 {
    font-weight: 600;
    font-size: 48px;
    line-height: 140%;
    color: #04091e;
    padding-top: 12px
}

.featured .featured-btn {
    text-align: end;
    margin-top: 5px
}

.featured .featured-btn a {
    color: #272931;
    border: 1.5px solid #747681;
    border-radius: 8px;
    font-weight: 600;
    font-size: 18px;
    line-height: 120%;
    display: inline-block;
    padding: 20px 40px;
    transition: all .5s
}

.featured .featured-btn:hover a {
    color: #2662fa;
    border-color: #2662fa
}

.featured .padd {
    padding: 0 20px
}

.featured .featured-top {
    margin-top: 61px
}

.featured .featured-item .featured-item-img {
    position: relative
}

.featured .featured-item .featured-item-img img {
    width: 100%
}

.featured .featured-item .featured-item-img:hover .featured-overlay {
    opacity: 1
}

.featured .featured-item .featured-item-img .featured-overlay {
    transition: all .5s;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg,rgba(255,255,255,0) -24.52%,rgba(0,12,43,0.58) 72.76%,rgba(0,12,43,0.85) 100%);
    border-radius: 10px
}

.featured .featured-item .featured-item-img .featured-overlay .featured-overlay-inner {
    padding: 0 45px;
    position: absolute;
    bottom: 39px
}

.featured .featured-item .featured-item-img .featured-overlay .featured-overlay-inner .featured-overlay-text h3 {
    padding-right: 5px
}

.featured .featured-item .featured-item-img .featured-overlay .featured-overlay-inner .featured-overlay-text h3 a {
    font-weight: 600;
    font-size: 32px;
    line-height: 160%;
    color: #fff
}

.featured .featured-item .featured-item-img .featured-overlay .featured-overlay-inner .featured-overlay-icon {
    display: flex;
    gap: 10px;
    align-items: center
}

.featured .featured-item .featured-item-img .featured-overlay .featured-overlay-inner .featured-overlay-icon .icon i {
    color: #ffce4c;
    border-radius: 2px
}

.featured .featured-item .featured-item-img .featured-overlay .featured-overlay-inner .featured-overlay-icon .icon i:last-child {
    color: #c4c4c4
}

.featured .featured-item .featured-item-img .featured-overlay .featured-overlay-inner .featured-overlay-icon .text span {
    font-weight: 400;
    font-size: 24px;
    color: #fff
}

.featured .featured-item .featured-item-img .featured-overlay .featured-overlay-btm-text span {
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    color: #fff;
    padding-right: 10px
}

.featured .featured-item .featured-item-img .featured-overlay .featured-overlay-btm-text span del {
    color: #c4c4c4;
    font-weight: 400
}

.featured .featured-item .featured-item-img .featured-overlay .featured-overlay-btm-img {
    text-align: end;
    margin-top: -37px
}

.product-details {
    background: url(/public/img/product/Product-details-bg.png) no-repeat center/cover;
    padding: 120px 0
}

.product-details .slick-dots button {
    display: block;
    width: 12px;
    height: 12px;
    padding: 0;
    border: none;
    border-radius: 100%;
    background-color: #2662fa;
    opacity: .2;
    text-indent: -9999px
}

.product-details .slick-active button {
    background-color: #2662fa;
    opacity: 1;
    height: 14px;
    width: 14px
}

.product-details-mt {
    padding-top: 40px;
    border-bottom: 2px solid #eceaff;
    padding-bottom: 20px
}

.product-details-text h2 {
    font-weight: 600;
    font-size: 48px;
    line-height: 140%;
    color: #04091e
}

.product-details-text-right p {
    font-weight: 600;
    font-size: 32px;
    color: #04091e
}

.product-details-text-right p del {
    color: #747681
}

.product-details-text-right p span {
    padding-left: 63px
}

.product-details-text-right-icon {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    margin-left: -57px
}

.product-details-text-right-icon .icon i {
    color: #ffce4c;
    border-radius: 2px
}

.product-details-text-right-icon .icon span i {
    color: #c4c4c4
}

.product-details-text-right-icon p {
    font-weight: 400;
    font-size: 24px;
    color: #04091e
}

.product-details-middel h5 {
    font-weight: 600;
    font-size: 24px;
    color: #2662fa;
    padding-top: 40px
}

.product-details-middel p {
    font-weight: 400;
    font-size: 18px;
    line-height: 152%;
    color: #747681;
    padding: 20px 0
}

.product-details-middel span {
    font-weight: 400;
    font-size: 18px;
    line-height: 152%;
    color: #747681
}

.additional-details h4 {
    font-weight: 600;
    font-size: 24px;
    color: #2662fa;
    padding-top: 20px
}

.additional-details-item {
    display: flex;
    gap: 66px;
    margin-top: 24px
}

.additional-details-item .additional-details-inner p {
    font-weight: 500;
    font-size: 18px;
    line-height: 152%;
    color: #111;
    padding-bottom: 12px
}

.additional-details-item .additional-details-inner p:last-child {
    padding-bottom: 0
}

.additional-details-item .additional-details-inner p span {
    color: #747681;
    padding-left: 20px
}

.additional-details-btn {
    margin-top: 56px
}

.additional-details-top {
    margin-top: 53px
}
