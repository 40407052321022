.collapse {
    visibility: visible !important;
}
.tab_hover{
  margin: auto;
  border-radius: 5px;
  box-shadow: 0px 20px 46px 2px #bcc6ff;
  cursor: n-resize;
  height: 500px;
}
.tab_hover img {
    width: 100%;
    object-fit: cover;
    object-position: top;
    height: 100% !important;
    transition: 8s all ease;
  }
  
.tab_hover:hover img{
      object-position: bottom;
}
.Product-img{
  margin: auto;
  border-radius: 5px;
  box-shadow: 0px 20px 46px 2px #bcc6ff;
  cursor: n-resize;
  height: 500px;
}
.Product-img img {
    width: 100%;
    object-fit: cover;
    object-position: top;
    height: 100% !important;
    transition: 8s all ease;
  }
  
.Product-img:hover img{
      object-position: bottom;
}
.portfolio-details-img {
  margin: auto;
  border-radius: 5px;
  box-shadow: 0px 20px 46px 2px #bcc6ff;
  cursor: n-resize;
  height: 500px;
}
.portfolio-details-img  img {
    width: 100%;
    object-fit: cover;
    object-position: top;
    height: 100% !important;
    transition: 10s all ease;
  }
  
.portfolio-details-img:hover img{
      object-position: bottom;
}
 
.header,
.menu-bg {
    position: fixed;
    width: 100%;
}
.menu-bg .btn-check:focus + .btn-outline-success,
.menu-bg .btn-outline-success:focus,
.menu-bg .form-control:focus {
    box-shadow: 0 0 0 0.25rem transparent;
}
#offCanvas-nav.open::before,
.container-full,
.header,
.header-two .nav-main .menu ul li a:hover::after,
.menu-bg,
.menu-bg .nav-main .menu ul li a:hover::after,
.resume-img img {
    width: 100%;
}
#nav-opn-btn,
.back-to-top i,
.btn-style-1,
.social-icons a i {
    background-color: #2662fa;
}
.btn-style-1{
    color: #fff;
}
.portfolio-item ul li,
.portfolio-item-two ul li {
    padding: 40px 28px;
    font-size: 28px;
    font-weight: 600;
}
.copyright-text h5 a,
.header-left-item .text p:hover,
.service-btn a {
    text-decoration-line: underline;
}
.header {
    padding: 12px 0;
    z-index: 9998;
}
.header .nav-bg {
    animation: 1s ease-out slideDown;
    margin-top: -52px;
    z-index: 9999;
}
.header .menu-padding {
    padding: 0;
}
@keyframes slideDown {
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(0);
    }
}
.header-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header-left {
    display: flex;
    gap: 32px;
}
.header-left-item {
    display: flex;
    gap: 12px;
    align-items: center;
}
.header-left-item .text a,
.header-left-item .text p {
    font-weight: 400;
    font-size: 16px;
    color: #747681;
    transition: 0.5s;
}
.header-right-inner .icon,
.header-two .header-right-inner .icon {
    display: flex;
    gap: 24px;
    line-height: 0;
}
.header-right-inner .icon i,
.header-two .header-right-inner .icon i {
    color: #747681;
    font-size: 20px;
    transition: 0.5s;
}
.footer-menu ul li a:hover,
.footer-services-menu ul li a:hover,
.header-right-inner .icon i:hover,
.menu-bg .nav-main .menu ul li a:hover,
.official-btn .official-btn-right:hover a,
.service-item .service-icon span {
    color: #2662fa;
}
.menu-bg {
    padding-top: 13px;
    z-index: 999;
    left: 0;
    right: 0;
    margin: auto;
}
.menu-bg .form-control {
    text-align: center;
    display: block;
    width: 100%;
    padding: 10px 14px;
    font-size: 16px;
    font-weight: 500;
    line-height: 0;
    color: #04091e;
    background-color: #04091e;
    border: 1px solid transparent;
    border-radius: 10px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.menu-bg .nav-main,
.menu-bg .nav-main .menu ul li ul {
    background-color: #fff;
    box-shadow: 5px 6px 60px rgba(17, 29, 59, 0.04);
}
.menu-bg .form-control:focus {
    color: #04091e;
    background-color: #04091e;
    border-color: transparent;
    outline: 0;
}
.menu-bg .btn-outline-success {
    color: #000;
    border-color: transparent;
    position: relative;
}
.menu-bg .btn-outline-success i {
    position: absolute;
    top: 13px;
    left: 46px;
}
.menu-bg .btn-outline-success:hover {
    color: #000;
    background-color: transparent;
    border-color: transparent;
}
.experience .experience-inner,
.freelance-head h3,
.resume-head,
.resume-skill {
    border-bottom: 1px solid #dcdeefb0;
}
.menu-bg .nav-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 14px;
    padding: 12px;
}
.menu-bg .nav-main .menu-main {
    display: flex;
    align-items: center;
    gap: 80px;
}
.btn-style-1 a,
.btn-style-2 a,
.btn-style-4 a {
    justify-content: center;
    display: flex;
    font-weight: 500;
    font-size: 16px;
}
.menu-bg .nav-main .menu ul {
    display: flex;
    gap: 38px;
    margin-bottom: 0;
    padding-left: 0;
}
.btn-style-1 a,
.btn-style-2 a,
.btn-style-3 a,
.btn-style-4 a {
    gap: 4px;
    font-family: work sans, sans-serif;
}
.freelance-item .freelance-img,
.menu-bg .nav-main .menu ul li,
.tab-item .tab-img {
    position: relative;
}
.menu-bg .nav-main .menu ul li:hover ul {
    opacity: 1;
    visibility: visible;
    transform: translateY(-8px);
}
.menu-bg .nav-main .menu ul li ul {
    display: block;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    width: 200px;
    transition: 0.5s linear;
    top: 62px;
    left: -75px;
    text-align: start;
    border-top: 2px solid #2662fa;
    padding: 10px 20px;
}
.menu-bg .nav-main .menu ul li ul li {
    line-height: 35px;
}
.menu-bg .nav-main .menu ul li a {
    font-weight: 500;
    font-size: 18px;
    color: #04091e;
    position: relative;
    transition: 0.3s ease-out;
    text-transform: capitalize;
}
.menu-bg .nav-main .menu ul li a:after {
    position: absolute;
    content: "";
    bottom: -5px;
    left: 0;
    width: 0%;
    height: 1px;
    background-color: #2662fa;
    transition: 0.3s ease-out;
}
.banner-btn-item .banner-btn-left,
.menu-btn {
    line-height: 0;
}
.btn-style-1,
.btn-style-2,
.btn-style-3,
.btn-style-4 {
    display: inline-block;
    width: 160px;
    height: 59px;
    line-height: 59px;
    border-radius: 8px;
    cursor: pointer;
    outline: 0;
    overflow: hidden;
}
.header-two .header-right-inner .icon i:hover,
.header-two .nav-main .menu ul li a:hover,
.service-two .service-item-two .service-icon span,
.tools-btn a span svg:hover {
    color: #daed1a;
}
.header-two .nav-main .menu ul li ul {
    border-top: 2px solid #daed1a;
}
.header-two .nav-main .menu ul li a {
    font-weight: 500;
    font-size: 18px;
    color: #04091e;
    position: relative;
    transition: 0.3s ease-out;
}
.header-two .nav-main .menu ul li a:after {
    position: absolute;
    content: "";
    bottom: -5px;
    left: 0;
    width: 0%;
    height: 1px;
    background-color: #daed1a;
    transition: 0.3s ease-out;
}
.btn-style-1 {
    text-align: center;
    position: relative;
    transition: 0.5s linear;
}
.btn-style-1 a,
.btn-style-4 a {
    color: #fff;
}
.btn-style-1 a span svg,
.btn-style-2 a span svg,
.btn-style-3 a span svg,
.btn-style-4 a span svg {
    transition: 0.5s linear;
    width: 24px;
}
.btn-style-1:hover {
    background-color: #042f9c;
    color: #fff;
}
.btn-style-1:hover:before {
    left: 120%;
    opacity: 0.25;
    transition: 1.3s cubic-bezier(0.19, 1, 0.22, 1);
}
.btn-style-1:hover a span svg,
.btn-style-2:hover a span svg,
.btn-style-3:hover a span svg,
.btn-style-4:hover a span svg {
    transform: translateX(10px);
}
.btn-style-1:before,
.btn-style-2:before,
.btn-style-3:before,
.btn-style-4:before {
    background-color: #fff;
    content: "";
    height: 150px;
    left: -75px;
    opacity: 0;
    position: absolute;
    top: -35px;
    transform: rotate(35deg);
    transition: 1.6s cubic-bezier(0.19, 1, 0.22, 1);
    width: 40px;
}
.btn-style-2 {
    text-align: center;
    position: relative;
    background-color: #daed1a;
    transition: 0.5s linear;
}
.btn-style-2 a {
    color: #04091e;
}
.btn-style-2:hover {
    background-color: #aebe0f;
    color: #fff;
}
.btn-style-2:hover:before,
.btn-style-3:hover:before,
.btn-style-4:hover:before {
    left: 120%;
    opacity: 0.5;
    transition: 1.3s cubic-bezier(0.19, 1, 0.22, 1);
}
.btn-style-3 {
    text-align: center;
    position: relative;
    background-color: #fdcc04;
    transition: 0.5s linear;
}
.btn-style-3 a {
    display: flex;
    color: #000;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
}
.back-to-top i,
.back-to-top-02 i {
    bottom: 40px;
    right: 50px;
    height: 50px;
    width: 50px;
    text-align: center;
    line-height: 50px;
    border-radius: 4px;
    font-size: 20px;
    animation: 1s ease-in infinite alternate jump;
    z-index: 555;
    transition: 0.3s;
    position: fixed;
}
.btn-style-3:hover {
    background-color: #e3b602;
    color: #000;
}
.btn-style-4 {
    text-align: center;
    position: relative;
    background-color: #ff3f00;
    transition: 0.5s linear;
}
.Project,
.Project2 {
    width: 100%;
    position: relative;
}
.btn-style-4:hover {
    background-color: #e33800;
    color: #fff;
}
@keyframes jump {
    50% {
        transform: translate3d(0, 0, 0);
    }
    100% {
        transform: translate3d(0, 30%, 0);
    }
}
.back-to-top i {
    cursor: pointer;
    color: #fff;
    display: none;
}
.back-to-top-02 i {
    background-color: #daed1a;
    color: #000;
    display: none;
}
#offCanvas-nav .m-nav .nav-links li a::before,
#offCanvas-nav .m-nav > .social-icons-s1 li a::before,
.mobile-header {
    display: none;
}
.mobile-header__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
}
#nav-opn-btn {
    width: 40px;
    height: 40px;
    border: none;
    color: #fff;
    border-radius: 5px;
    font-size: 18px;
}
.p-right-2 #nav-opn-btn {
    width: 40px;
    height: 40px;
    border: none;
    background-color: #fdcc04;
    color: #04091e;
    border-radius: 5px;
    font-size: 18px;
}
.p-right-3 #nav-opn-btn,
.p-right-4 #nav-opn-btn {
    border: none;
    font-size: 18px;
    height: 40px;
    width: 40px;
}
.p-right-3 #nav-opn-btn {
    background-color: #ff3f00;
    color: #fff;
    border-radius: 5px;
}
.p-right-4 #nav-opn-btn {
    background-color: #daed1a;
    color: #04091e;
    border-radius: 5px;
}
#offCanvas-nav {
    background: 0 0;
    position: absolute;
    left: -300px;
    top: 0;
    width: 300px;
    height: 100vh;
    z-index: 999999;
    transition: 0.5s;
}
#offCanvas-nav::before {
    content: "";
    width: 0%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    transition: 0.5s 0.3s;
}
#offCanvas-nav.open {
    left: 0;
}
#offCanvas-nav .m-nav {
    position: relative;
    background-color: #fff;
    padding: 80px 25px 50px;
    z-index: 9;
    display: block;
    height: 100%;
    overflow-y: scroll;
}
#nav-cls-btn {
    position: absolute;
    right: 15px;
    top: 15px;
    background: 0 0;
    border: none;
    outline: 0;
    font-size: 22px;
}
#offCanvas-nav .m-nav .nav-links {
    flex-direction: column;
    align-items: stretch;
    gap: 15px;
}
.banner-btn-right,
.resume-head {
    align-items: center;
    display: flex;
}
#offCanvas-nav .m-nav .nav-links li {
    border-bottom: 1px solid #d3d3d3;
    margin-bottom: 20px;
}
#offCanvas-nav .m-nav .nav-links li a {
    font-size: 16px;
    color: #04091e;
}
#offCanvas-nav li:last-of-type {
    border: 0 !important;
}
.banner-btn-right .video button i,
.social-icons a i,
.social-icons-2 a i,
.social-icons-3 a i,
.social-icons-4 a i {
    border-radius: 50px;
    font-size: 20px;
    text-align: center;
}
#offCanvas-nav .m-nav .nav-links .dropdown .d-menu {
    position: relative;
    box-shadow: none;
    padding-left: 20px;
    padding-top: 15px !important;
    visibility: visible;
    opacity: 1;
    display: none;
    transition: 0.5s ease-in;
}
#offCanvas-nav .m-nav .nav-links .dropdown .d-menu li {
    padding: 5px 0;
}
#offCanvas-nav .m-nav .nav-links .dropdown .d-menu li a {
    font-size: 15px;
    color: #000;
}
#offCanvas-nav .m-nav .nav-links .dropdown:hover .d-menu,
.accordion-form .accordion-tittle span {
    display: block;
}
#offCanvas-nav .m-nav .logo {
    margin-bottom: 40px;
}
#offCanvas-nav .m-nav > .social-icons-s1 {
    gap: 12px;
    margin-top: 50px;
}
.social-icons,
.social-icons-2,
.social-icons-3,
.social-icons-4 {
    display: flex;
    gap: 20px;
}
.social-icons a i {
    height: 40px;
    width: 40px;
    color: #fff;
    line-height: 40px;
    transition: 0.5s;
}
.social-icons a i:hover,
.social-icons-3 a i:hover {
    background-color: #04091e;
    color: #fff;
}
.social-icons-2 a i {
    height: 40px;
    width: 40px;
    background-color: #fdcc04;
    color: #04091e;
    line-height: 40px;
    transition: 0.5s;
}
.social-icons-2 a i:hover {
    background-color: #04091e;
    color: #fdcc04;
}
.social-icons-3 a i {
    height: 40px;
    width: 40px;
    background-color: #ff3f00;
    color: #fff;
    line-height: 40px;
    transition: 0.5s;
}
.social-icons-4 a i {
    height: 40px;
    width: 40px;
    background-color: #daed1a;
    color: #04091e;
    line-height: 40px;
    transition: 0.5s;
}
.social-icons-4 a i:hover {
    background-color: #04091e;
    color: #daed1a;
}
@keyframes banner {
    50% {
        transform: translateY(-20px);
    }
}
@keyframes up {
    0% {
        width: 0;
    }
    15% {
        width: 100%;
    }
    85% {
        opacity: 1;
    }
    90% {
        width: 100%;
        opacity: 0;
    }
    100% {
        width: 0;
        opacity: 0;
    }
}
@keyframes borderanimate2 {
    0% {
        transform: translate(-50%, -50%) scale(0.8);
    }
    60% {
        opacity: 1;
    }
    100% {
        transform: translate(-50%, -50%) scale(2.5);
        opacity: 0;
    }
}
.banner {
    background: url("../public/img/banner/Hero.jpg") center/cover no-repeat;
    padding-top: 210px;
}
.banner-btn-right .video button,
.banner-text,
.blog .blog-head,
.service-head,
.thought-text {
    position: relative;
    z-index: 1;
}
.banner-vic,
.service-vic {
    z-index: -1;
    position: absolute;
}
.banner-text h1 {
    font-weight: 700;
    font-size: 65px;
    color: #04091e;
    margin-bottom: 0;
    line-height: 92px;
    letter-spacing: -0.01em;
}
.banner-text p {
    font-weight: 400;
    font-size: 24px;
    line-height: 150%;
    color: #747681;
    padding-top: 14px;
    margin-right: 50px;
}
.text p,
.text span {
    font-weight: 500;
    font-size: 15px;
}
.banner-vic {
    bottom: 106px;
    left: 0;
}
.banner-vic-1 {
    position: absolute;
    bottom: -144px;
    left: -211px;
    animation: 7s linear infinite banner;
}
.banner-btn {
    margin-top: 46px;
}
.banner-btn-item {
    display: flex;
    gap: 36px;
}
.banner-btn-right {
    gap: 20px;
}
.banner-btn-right .video button i {
    height: 60px;
    width: 60px;
    line-height: 60px;
    color: #2662fa;
    border: 1.5px solid rgba(38, 98, 250, 0.662745098);
    background-color: #f4f8fa;
}
.banner-btn-right .video button::after {
    position: absolute;
    content: "";
    top: 30px;
    left: 50%;
    height: 45px;
    width: 45px;
    background-color: rgba(59, 130, 246, 0.5);
    animation: 2s linear infinite borderanimate3;
    z-index: -1;
    border-radius: 50px;
    transition: 0.3s linear;
}
.text span {
    color: #04091e;
    letter-spacing: 3px;
}
.text p {
    color: #747681;
}
@keyframes borderanimate3 {
    0% {
        transform: translate(-50%, -50%) scale(0.8);
    }
    60% {
        opacity: 1;
    }
    100% {
        transform: translate(-50%, -50%) scale(2.5);
        opacity: 0;
    }
}
.resume {
    padding: 0 0 120px;
    background-color: #ececf7;
}
.resume-2 {
    padding: 120px 0 33px;
}
.resume-main {
    padding: 60px 60px 100px !important;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 12px;
}
.resume-title {
    margin-bottom: 60px;
}
.resume-title h2 {
    font-weight: 600;
    font-size: 48px;
    color: #000;
}
.resume-head {
    gap: 44px;
}
.footer-link-text h2,
.footer-services-text h2,
.official-text h2,
.resume-head .resume-head-left h5 {
    font-weight: 600;
    font-size: 24px;
    color: #fff;
    margin-bottom: 12px;
}
.resume-head .resume-head-left p {
    font-weight: 400;
    font-size: 18px;
    color: #000;
    padding-top: 4px;
}
.resume-head .resume-head-right {
    display: flex;
    align-items: center;
    gap: 8px;
}
.resume-head .resume-head-right .left h2 {
    font-weight: 700;
    font-size: 72px;
    color: #daed1a;
}
.resume-head .resume-head-right .right p,
.resume-head .resume-head-right .right span {
    font-weight: 500;
    font-size: 18px;
    line-height: 152%;
    color: #000;
}
.resume-head-p {
    padding-top: 18px;
}
.resume-head-p p {
    font-weight: 400;
    font-size: 18px;
    line-height: 152%;
    color: #000;
}
.experience .experience-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px 17px 16px;
}
.experience .experience-inner .experience-text h5,
.experience .experience-inner-two .experience-text h5 {
    font-weight: 600;
    font-size: 20px;
    color: #000;
}
.experience .experience-inner .experience-text .experience-img,
.experience .experience-inner-two .experience-text .experience-img {
    padding-top: 16px;
}
.experience-img img {
    width: 100px;
}
.experience .experience-inner .experience-btn a {
    padding: 7px 20px;
    background-color: #daed1a;
    border-radius: 6px;
    font-weight: 400;
    font-size: 18px;
    color: #050f25;
    display: inline-block;
    text-align: center;
}
.experience .experience-inner-two {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 10px 17px 16px;
    border-bottom: 1px solid #dcdeefb0;
}
.experience .experience-inner-two .experience-btn a {
    padding: 5px 10px;
    border: 1px solid #0a58ca;
    border-radius: 6px;
    font-weight: 400;
    font-size: 18px;
    color: #0a58ca;
    text-align: center;
}
.skill-btn{
    position: relative;
}
.skill-btn::after{
    content: "";
    position:absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: 80%;
    bottom: 0;
    margin: auto ;
    background-color: #dcdeefb0;
}
.resume-skill {
    display: flex;
    margin-top: 20px;
    padding-bottom: 20px;
}
.resume-skill h3 {
    font-weight: 400;
    font-size: 24px;
    color: #000;
}
.skill-btn {
    display: inline-flex;
    gap: 40px;
    flex-wrap: wrap;
    margin-top: 47px;
}
.tab-item .tab-img .tab-img-overlay .tab-img-overlay-text .tab-over-btn,
.tab-item .tab-img .tab-img-overlay .tab-img-overlay-text .tab-over-btn-2,
.tools-btn {
    margin-top: 27px;
}
.skill-btn a {
    padding: 4px 14px;
    background-color: #16213b;
    border-radius: 6px;
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;
    color: #fff;
    margin-top: -20px;
    display: inline-block;
    transition: 0.5s;
}
.portfolio-item ul li:hover,
.skill-btn .active-two,
.skill-btn a:hover {
    background-color: #daed1a;
    color: #050f25;
}
.tools-btn {
    display: flex;
    gap: 48px;
    flex-wrap: wrap;
    margin-right: 63px;
}
.tools-btn a span svg {
    fill: currentColor;
    color: #1d2a47;
    transition: 0.5s;
}
.service-two .service-item-two .service-icon span svg circle,
.tools-btn .active-three span svg {
    fill: currentColor;
    color: #daed1a;
}
.freelance-head {
    margin-top: 81px;
}
.freelance-head h3 {
    font-weight: 600;
    font-size: 24px;
    color: #f4f4f4;
    padding-bottom: 7px;
}
.freelance-item {
    margin-top: 40px;
    display: flex;
    gap: 60px;
}
.freelance-item .freelance-img::before {
    position: absolute;
    content: "";
    top: 20px;
    left: -30px;
    width: 1px;
    height: 64px;
    background-color: rgba(255, 255, 255, 0.15);
}
.freelance-item .freelance-img:first-child::before {
    width: 0;
}
.freelance-item .freelance-img img {
    opacity: 0.2;
    transition: 0.5s;
}
.customers .customers-item .customers-inner .slick-center.customers-img img,
.freelance-item .freelance-img img:hover,
.service .slick-center .service-item .service-overelay,
.service .slick-center .service-item:hover .service-overelay,
.service-item:hover .service-overelay,
.service-two .service-item-two:hover .service-overelay,
.service-two .slick-center .service-item-two .service-overelay,
.tab-item .tab-img:hover .tab-img-overlay {
    opacity: 1;
}
.freelance-item .active-four img {
    opacity: 1;
    transition: 0.5s;
}
.freelance-item .active-four img:hover {
    opacity: 0.2;
}
.skills-two .skill-rigght-btn-two .btn-style-1 {
    background-color: #ffd233;
}
.skills-three .skill-rigght-btn-two .btn-style-2 a,
.skills-two .skill-rigght-btn-two .btn-style-1 a {
    font-family: work sans, sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #04091e;
    gap: 4px;
}
.skills-three .skill-rigght-btn-two .btn-style-2 a span svg,
.skills-two .skill-rigght-btn-two .btn-style-1 a span svg {
    transition: 0.5s linear;
    width: 24px;
    color: #04091e;
}
.skills-1 {
    margin-top: 118px;
}
.slick-two-2 {
    padding-top: 0;
}
.portfolio {
    padding: 32px 0;
    background-color: #ececf7;
}
.customers .customers-text,
.portfolio-item,
.portfolio-item-two,
.tab-head,
.tab-item .tab-img .tab-img-overlay .tab-img-overlay-text {
    text-align: center;
}
.Project-head-middle .Project-head-middle-item .text ul,
.portfolio-item ul,
.portfolio-item-two ul {
    margin: 0;
    padding: 0;
}
.portfolio-item ul li {
    background-color: #fff;
    border: 1px dashed #fff;
    border-radius: 10px;
    color: #000;
    transition: 0.4s;
}
.portfolio-item ul li span svg {
    fill: currentColor;
    color: #000;
    transition: 0.4s;
}
.portfolio-item ul li:hover span svg {
    fill: currentColor;
    color: #04091e;
}
.portfolio-item-two ul li {
    background-color: #daed1a;
    border: 1px dashed rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    color: #050f25;
    transition: 0.4s;
}
.portfolio-item-two ul li span svg {
    fill: currentColor;
    color: #050f25;
    transition: 0.4s;
}
.service {
    padding: 124px 0 120px;
}
.customers .slick-dots,
.service .slick-dots,
.service-two .slick-dots {
    display: flex;
    justify-content: center;
    list-style-type: none;
    margin: 60px 0 0;
    gap: 12px;
    padding: 0;
}
.customers .slick-dots button,
.service .slick-dots button {
    display: block;
    width: 12px;
    height: 12px;
    padding: 0;
    border: none;
    border-radius: 100%;
    background-color: #2662fa;
    opacity: 0.2;
    text-indent: -9999px;
}
.customers .slick-dots li.slick-active button,
.service .slick-dots li.slick-active button {
    background-color: #2662fa;
    opacity: 1;
    height: 14px;
    width: 14px;
}
.Project-right-item:hover,
.service .slick-center .service-item {
    background-color: #2662fa;
}
.service .slick-center .service-item .service-icon span svg .path,
.service .slick-center .service-item:hover .service-icon span svg .path,
.service-item:hover .service-icon span svg .path {
    stroke: #fff;
}
.Project-right-item:hover .Project-right-item-text h3,
.Project-right-item:hover .Project-right-item-text p,
.service .slick-center .service-item .service-btn a,
.service .slick-center .service-item .service-icon span svg .circle,
.service .slick-center .service-item .service-item-text h3,
.service .slick-center .service-item .service-item-text p,
.service .slick-center .service-item:hover .service-btn a,
.service .slick-center .service-item:hover .service-icon span svg .circle,
.service .slick-center .service-item:hover .service-item-text h3,
.service .slick-center .service-item:hover .service-item-text p,
.service-item:hover .service-btn a,
.service-item:hover .service-icon span svg .circle,
.service-item:hover .service-item-text h3,
.service-item:hover .service-item-text p,
.service-two .service-head h2,
.service-two .service-item-two:hover .service-btn a,
.service-two .service-item-two:hover .service-item-text h3,
.tap-btn .btn-style-2 a {
    color: #fff;
}
.service .slick-center .service-item:hover,
.service-item:hover {
    background-color: #2662fa;
    box-shadow: 0 10px 50px rgba(29, 29, 29, 0.05);
    border-radius: 10px;
}
.project-4 .project-section,
.project-bg {
    box-shadow: 0 9px 126px rgba(20, 0, 255, 0.06);
}
.inner-top {
    margin-top: 40px;
}
.blog .blog-p,
.m-r {
    padding: 0 15px;
}
.inner-mt {
    margin-top: 30px;
}
.service-head h2 {
    font-weight: 700;
    font-size: 48px;
    line-height: 140%;
    color: #04091e;
}
.service-vic {
    top: -7px;
    right: 42px;
}
.service-text p {
    font-weight: 400;
    font-size: 18px;
    line-height: 160%;
    color: #747681;
}
.service-item {
    padding: 30px 28px;
    border: 1px solid rgba(29, 29, 29, 0.05);
    border-radius: 10px;
    transition: background-color 0.5s;
    background-color: #fff;
    position: relative;
    overflow: hidden;
    z-index: 1;
}
.service-item .service-overelay {
    position: absolute;
    width: 319px;
    height: 319px;
    border: 80px solid rgba(255, 255, 255, 0.0666666667);
    border-radius: 500px;
    bottom: -146px;
    right: -95px;
    z-index: -1;
    opacity: 0;
    transition: 0.5s;
}
.service-item .service-icon span svg .path,
.service-two .service-item-two .service-icon span svg .path {
    stroke: currentColor;
}
.service-item .service-icon span svg circle {
    fill: currentColor;
    color: #2662fa;
}
.service-item .service-item-text h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
    color: #04091e;
    padding-top: 24px;
}
.service-item .service-item-text p {
    font-weight: 400;
    font-size: 18px;
    line-height: 152%;
    color: #747681;
    letter-spacing: -0.01em;
    padding-top: 24px;
}
.service-btn {
    padding-top: 20px;
}
.service-btn a {
    font-weight: 400;
    font-size: 18px;
    color: #04091e;
}
.service-btn a i {
    transform: rotate(-45deg);
}
.service-two {
    background-color: #050f25;
    padding: 92px 0 120px;
}
.service-two .slick-center .service-item-two {
    border: 1px solid #daed1a;
    background-color: #091530;
}
.service-two .slick-center .service-item-two:hover {
    background-color: #091530 !important;
}
.project-4 .project-section .project-counter-box:hover,
.slick-dots button {
    background-color: #fdcc04;
}
.service-two .mar-top {
    margin-top: 42px;
}
.service-two .slick-dots button {
    display: block;
    width: 12px;
    height: 12px;
    padding: 0;
    border: none;
    border-radius: 100%;
    background-color: #daed1a;
    text-indent: -9999px;
}
.service-two .slick-dots li.slick-active button {
    background-color: #daed1a;
    opacity: 1;
    height: 14px;
    width: 14px;
}
.service-two .service-item-two:hover .service-item-text p,
.service-two .service-text p {
    color: #f5f5f5;
}
.service-two .service-item-two {
    padding: 30px 28px;
    border: 1px solid rgba(29, 29, 29, 0.05);
    border-radius: 10px;
    transition: background-color 0.5s;
    background-color: #091530;
    position: relative;
    overflow: hidden;
    z-index: 1;
    margin: 0 6px;
}
.service-two .service-item-two .service-overelay {
    position: absolute;
    width: 319px;
    height: 319px;
    border: 80px solid rgba(255, 255, 255, 0.0823529412);
    border-radius: 500px;
    bottom: -146px;
    right: -95px;
    z-index: -1;
    opacity: 0;
    transition: 0.5s;
}
.service-two .service-item-two .service-item-text h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
    color: #fff;
    padding-top: 24px;
}
.service-two .service-item-two .service-item-text p {
    font-weight: 400;
    font-size: 18px;
    line-height: 152%;
    color: #f5f5f5;
    letter-spacing: -0.01em;
    padding-top: 24px;
}
.service-two .service-item-two:hover {
    background-color: #091530;
    box-shadow: 0 10px 50px rgba(29, 29, 29, 0.05);
    border-radius: 10px;
    border: 1px solid #daed1a;
}
.service-two .service-item-two:hover .service-icon span svg .path {
    stroke: transparent;
}
.service-two .service-item-two:hover .service-icon span svg .circle {
    color: transparent;
}
.Project {
    background: url("/public/img/project/Project-bg.png") center/cover no-repeat;
    min-height: 631px;
    z-index: -1;
}
.Project2 {
    min-height: 631px;
    z-index: -1;
    background-color: transparent;
}
.Project-two-three {
    background-color: #f7f7fd;
    z-index: 1;
    position: absolute;
    width: 100%;
}
.project-bg {
    padding: 62px 54px !important;
    margin: 0 0 -20px;
    background-color: #fff;
    z-index: 222;
    margin-top: -360px !important;
    border-radius: 4px;
    border: 1px solid #eceaff;
    position: relative;
    overflow: hidden;
}
.project-bg::before {
    position: absolute;
    content: "";
    top: 240px;
    left: -149px;
    width: 470px;
    height: 470px;
    border: 100px solid #2662fa;
    opacity: 0.08;
    border-radius: 50%;
}
.Project-pt {
    padding-top: 40px;
}
.Project-head h2 {
    font-weight: 700;
    font-size: 48px;
    line-height: 128%;
    color: #04091e;
}
.Project-head p {
    font-weight: 400;
    font-size: 18px;
    line-height: 152%;
    color: #747681;
    padding-top: 18px;
}
.Project-head-middle {
    margin-top: 18px;
}
.Project-head-middle .Project-head-middle-item {
    display: flex;
    align-items: center;
    gap: 14px;
    padding-bottom: 15px;
}
.Project-head-middle .Project-head-middle-item:last-child,
.footer-menu ul li:last-child,
.footer-services-menu ul li:last-child,
.project-4 .project-section .project-content .project-list li:last-child {
    padding-bottom: 0;
}
.Project-head-middle .Project-head-middle-item .icon i,
.official-inner .icon i {
    color: #2662fa;
    font-size: 20px;
}
.Project-head-middle .Project-head-middle-item .text ul li {
    font-weight: 500;
    font-size: 18px;
    color: #04091e;
    font-family: work sans, sans-serif;
}
.Project-btn {
    margin-top: 45px;
    line-height: 0;
}
.Project-right-item {
    padding: 0 33px 24px;
    background-color: #f2f6fe;
    border: 1px solid #f4f4f4;
    border-radius: 4px;
    transition: background-color 0.5s;
    z-index: 222;
    margin: 0 10px;
}
#tab ul li:hover,
.accordion-button,
.accordion-item {
    background-color: #fff;
}
.Project-right-item .Project-right-icon {
    display: flex;
    justify-content: center;
}
.Project-right-item .Project-right-icon .icon {
    width: 80px;
    height: 80px;
    background-color: #2662fa;
    border-radius: 50px;
    text-align: center;
    line-height: 80px;
    margin-top: -35px;
    transition: 0.5s;
    border: 1px solid #2662fa;
}
.Project-right-item:hover .Project-right-icon .icon {
    border: 1px solid #fff;
}
.Project-right-item-text h3 {
    text-align: center;
    font-weight: 600;
    font-size: 40px;
    color: #04091e;
    padding-top: 30px;
    transition: 0.5s;
    line-height: 35px;
}
.Project-right-item-text p {
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    text-transform: capitalize;
    color: #747681;
    transition: 0.5s;
    line-height: 35px;
}
.Project-right-top {
    margin-top: 50px;
}
.mar-top {
    margin-top: 72px;
}
.project-4 {
    padding: 120px 0 0;
    min-height: 840px !important;
}
.project-4 .project-section {
    background: #fff;
    padding: 61px 38px 31px 57px;
    border-radius: 4px;
    margin-top: 170px;
}
.accordion-button::after,
.tab-head h2::after {
    background-size: cover;
    background-repeat: no-repeat;
}
.project-4 .project-section .project-content .project-tittle p {
    padding: 18px 0;
}
.project-4 .project-section .project-content .project-list li {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
}
.project-4 .project-section .project-content .project-list li i {
    margin-right: 14px;
    font-size: 16px;
    color: #fdcc04;
}
.accordion-form .accordion-flush .accordion-item h3,
.project-4 .project-section .project-content .project-list li p,
.project-4 .project-section .project-counter-box:hover p,
.tap-btn .btn-style-2 a:hover {
    color: #04091e;
}
.project-4 .project-section .project-content .project-btn {
    margin-top: 44px;
}
.project-4 .project-section .project-counter-box {
    text-align: center;
    background-color: #f5f5f5;
    border: 1px solid #f4f4f4;
    padding: 24px 36px;
    margin: 30px 10px;
    border-radius: 4px;
    transition: 0.5s linear;
}
.project-4 .project-section .project-counter-box .counter-img {
    margin-top: -60px;
}
.footer-logo-text,
.project-4 .project-section .project-counter-box .counter-content {
    padding-top: 21px;
}
#tab {
    padding: 105px 0 120px;
    background: url("/public/img/product/Product-bg.png") center/cover no-repeat;
}
#tab .shuffle-filter .course-filter li .active {
    background: #47d990;
}
#tab ul li {
    padding: 10px 16px;
    border: 1px solid #1a253f;
    border-radius: 8px;
    color: #000;
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    cursor: pointer;
    transition: 0.5s;
}
#tab ul li:hover {
    color: #04091e;
}
.tab-head h2 {
    font-weight: 700;
    font-size: 48px;
    line-height: 140%;
    color: #000;
    position: relative;
    z-index: 1;
    padding-bottom: 5px;
}
.tab-head h2::after {
    position: absolute;
    content: "";
    bottom: 19px;
    right: 41px;
    width: 225px;
    height: 10px;
    z-index: -1;
}
.tab-head p {
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;
    color: #000;
}
.tab-item .tab-img img {
    height: 370px;
    width: 100%;
}
.tab-item .tab-img .tab-img-overlay {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(4, 9, 30, 0.5137254902);
    transition: 0.5s ease-out;
}
.tab-item .tab-img .tab-img-overlay .over-d-f {
    display: flex;
    justify-content: center;
    margin-top: 100px;
}
.tab-item .tab-img .tab-img-overlay .tab-img-overlay-text h4 {
    font-weight: 600;
    font-size: 28px;
    line-height: 150%;
    color: #fff;
}
.tab-item .tab-img .tab-img-overlay .tab-img-overlay-text h5,
.tab-item .tab-img .tab-img-overlay .tab-img-overlay-text p {
    font-weight: 400;
    font-size: 18px;
    line-height: 152%;
    color: #fff;
    padding-top: 4px;
    position: relative;
}
.tab-item .tab-img .tab-img-overlay .tab-img-overlay-text p::before {
    position: absolute;
    content: "";
    top: 12px;
    left: 119px;
    height: 8px;
    width: 8px;
    background-color: #daed1a;
    border-radius: 50px;
}
.tab-item .tab-img .tab-img-overlay .tab-img-overlay-text h5::before {
    position: absolute;
    content: "";
    top: 12px;
    left: 152px;
    height: 8px;
    width: 8px;
    background-color: #daed1a;
    border-radius: 50px;
}
.tab-item .tab-img .tab-img-overlay .tab-img-overlay-text .tab-over-btn a,
.tab-item .tab-img .tab-img-overlay .tab-img-overlay-text .tab-over-btn-2 a {
    padding: 8px 12px;
    border: 1px solid #daed1a;
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 180%;
    display: inline-block;
    color: #daed1a;
    transition: 0.5s;
}
.tab-item .tab-img .tab-img-overlay .tab-img-overlay-text .tab-over-btn a:hover {
    background-color: #2662fa;
    border: 1px solid #2662fa;
    color: #fff;
}
.tab-item .tab-img .tab-img-overlay .tab-img-overlay-text .tab-over-btn-2 a:hover {
    background-color: #daed1a;
    border: 1px solid #daed1a;
    color: #04091e;
}
.s-mt {
    margin-bottom: 30px;
}
.course-filter {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    margin: 30px 0 60px;
}
.course-filter .active {
    background-color: #fff;
    color: #04091e !important;
}
.accordion-form .accordion-flush .accordion-item .accordion-button:not(.collapsed),
.tap-btn .btn-style-2 {
    background-color: transparent;
}
.tap-btn {
    text-align: center;
    margin-top: 60px;
}
.tap-btn .btn-style-2 {
    border-radius: 8px;
    display: inline-block;
    font-weight: 500;
    font-size: 18px;
    line-height: 152%;
    transition: 0.5s;
}
.shuffle {
    min-height: 770px;
}
.thought,
.thought-2,
.thought-three {
    padding: 120px 0;
}
.thought-two {
    padding: 312px 0 118px;
}
.thought-3 {
    padding: 135px 0 120px;
}
.thought-text h2 {
    font-weight: 700;
    font-size: 50px;
    line-height: 140%;
    color: #04091e;
}
.p-r {
    padding-right: 35px;
}
.thought-vic {
    position: absolute;
    right: 10px;
    top: 8px;
    z-index: -1;
}
.accordion-button {
    font-family: work sans, sans-serif;
    padding: 13px 0 12px;
    font-weight: 500;
    font-size: 24px;
    color: #04091e;
}
.accordion-button span {
    width: 32px;
    height: 32px;
    color: #04091e;
    text-align: center;
    line-height: 32px;
    border-radius: 50px;
    font-weight: 500;
    font-size: 18px;
    margin-right: 20px;
}
.accordion-body p,
.form-label {
    line-height: 152%;
    font-size: 18px;
}
.accordion-button:focus {
    z-index: 3;
    border-color: transparent;
    outline: 0;
    box-shadow: 0 0 0 0.25rem transparent;
}
.accordion-flush .accordion-item:last-child,
.accordion-item {
    border-bottom: 1px solid rgba(116, 118, 129, 0.3960784314);
}
.accordion-button:not(.collapsed) {
    color: #04091e;
    background-color: transparent;
    box-shadow: inset 0 -1px 0 transparent;
}
.accordion-button:not(.collapsed) span {
    width: 32px;
    height: 32px;
    color: #fff;
    text-align: center;
    line-height: 32px;
    border-radius: 50px;
    background-color: #04091e;
}
.accordion-button:not(.collapsed)::after {
    transform: rotate(-180deg);
}
.accordion-button::after {
    width: 13px;
    height: 7px;
    background-position: center;
}
.accordion-flush .accordion-item .accordion-button,
.accordion-form .accordion-flush .accordion-item .accordion-collapse:last-of-type {
    border-radius: 0;
}
.accordion-flush .accordion-collapse,
.accordion-form .accordion-flush .accordion-item .accordion-collapse {
    border-bottom: 2px solid #04091e;
}
.accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.accordion-body {
    padding: 0 0 28px 50px;
}
.accordion-body p {
    font-weight: 400;
    color: #04091e;
}
.accordion-body a {
    padding-top: 10px;
    font-weight: 400;
    font-size: 18px;
    color: #2662fa;
    display: flex;
    align-items: center;
    gap: 30px;
}
.send .send-item {
    background: url("/public/img/contact/send.png") center/cover no-repeat;
    padding: 52px 32px 67px;
}
.send .send-item .send-inner {
    display: flex;
    gap: 60px;
    padding-bottom: 14px;
}
.send .send-item .send-btn {
    text-align: end;
    margin-top: 38px;
    line-height: 0;
}
.mb-3 {
    margin-bottom: 0 !important;
    width: 100%;
}
.form-label {
    margin-bottom: 2px;
    font-weight: 500;
    color: #747681;
    font-family: work sans, sans-serif;
}
.form-control {
    display: block;
    width: 100%;
    padding: 14px 16px;
    font-weight: 400;
    font-size: 14px;
    font-family: work sans, sans-serif;
    border: 1px solid #f4f4f4;
    border-radius: 8px;
}
.form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #2662fa;
    outline: 0;
    box-shadow: 0 0 0 0.25rem transparent;
}
.accordion-form {
    padding: 0 0 120px;
}
.accordion-form .accordion-tittle {
    z-index: 1;
    padding-bottom: 15px;
}
.accordion-form .accordion-tittle .accordion-tittle-img {
    z-index: -1;
    top: -2px;
    right: 45px;
}
.accordion-form .accordion-flush .accordion-item {
    border-bottom: 1px solid rgba(116, 118, 129, 0.344);
}
.accordion-form .accordion-flush .accordion-item .accordion-button {
    padding: 25px 0;
}
.accordion-form .accordion-flush .accordion-item .accordion-button:focus {
    border: transparent;
    box-shadow: none;
    outline: 0;
}
.accordion-form .accordion-flush .accordion-item .accordion-button:not(.collapsed) span {
    background: #04091e;
    color: #fff;
}
.accordion-form .accordion-flush .accordion-item .accordion-button span {
    width: 36px;
    height: 35px;
    color: #04091e;
    display: flex;
    font-size: 18px;
    line-height: 35px;
    font-weight: 500;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    margin-right: 20px;
    transition: 0.4s;
}
.accordion-form .accordion-flush .accordion-item .accordion-body {
    padding: 0 52px 26px;
}
.accordion-form .accordion-flush .accordion-item .accordion-body p {
    padding-bottom: 9px;
}
.accordion-form .accordion-flush .accordion-item .accordion-body a {
    text-decoration: underline;
    font-weight: 400;
}
.accordion-form .accordion-flush .accordion-item .accordion-body a i {
    margin-left: 30px;
}
.accordion-form .form {
    padding: 52px 32px 67px;
}
.accordion-form .form .form-control {
    padding: 14px 16px;
    font-size: 14px;
    border: transparent;
    text-decoration: inherit;
}
.accordion-form .form .form-control:focus {
    box-shadow: none;
    border: transparent;
}
.accordion-form .form .form-btn {
    margin-top: 35px;
}
.customers {
    padding: 93px 0 120px;
    background: url("../public/img/customer/Customer-bg.png") center/cover no-repeat;
}
.customers .customer-head-inner .customer-head-innner-df .customer-head-inner-text h4,
.customers .customers-text h2 {
    font-weight: 600;
    font-size: 24px;
    color: #04091e;
}
.customers .customers-item {
    position: relative;
    margin-top: 17px;
}
.customers .customers-item::before {
    position: absolute;
    content: "";
    top: 70px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #cdd6d7;
}
.customers .customers-item .customers-inner .slick-center.customers-img::before {
    width: 100% !important;
}
.customers .customers-item .customers-inner .customers-img {
    position: relative;
    padding-bottom: 32px;
    display: flex;
    justify-content: center;
}
.customers .customers-item .customers-inner .customers-img::before {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    width: 0%;
    height: 2px;
    background-color: #000;
    transition: 0.5s;
}
.customers .customers-item .customers-inner .customers-img img {
    opacity: 0.3;
    transition: 0.5s;
    width: 100px;
    height: 40px;
}
.customers .customer-head {
    text-align: center;
    margin-top: 71px;
}
.customers .customer-head span {
    font-weight: 500;
    font-size: 20px;
    color: #2662fa;
    line-height: 32px;
}
.customers .customer-head h2 {
    font-weight: 700;
    font-size: 48px;
    color: #04091e;
    line-height: 67.2px;
}
.customers .customer-head-item-slick {
    margin-top: 33px;
}
.customers .customer-head-item {
    padding: 39px 33px 31px 40px;
    background-color: #fff;
    border-radius: 4px;
}
.customers .customer-head-item .icon {
    display: flex;
    gap: 10px;
}
.customers .customer-head-item .icon i {
    font-size: 20px;
    color: #fdcc04;
}
.customers .customer-head-item .text p {
    font-style: italic;
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;
    color: #747681;
    padding-top: 16px;
}
.customers .customer-head-inner {
    display: flex;
    justify-content: space-between;
}
.customers .customer-head-inner .customer-head-innner-df {
    display: flex;
    align-items: center;
    gap: 14px;
    padding-top: 35px;
}
.customers .customer-head-inner .customer-head-innner-df .customer-head-inner-text p {
    font-weight: 400;
    font-size: 18px;
    color: #747681;
}
.customers .mart {
    padding: 0 20px;
}
.customers-1 {
    padding: 95px 0 120px;
}
.blog {
    background: url("/public/img/blog/blog.png") center/cover no-repeat;
    padding: 105px 0 120px;
}
.blog .blog-head h2 {
    font-weight: 700;
    font-size: 48px;
    line-height: 140%;
    color: #04091e;
    text-align: center;
    padding: 0 290px;
}
.blog .blog-vic {
    position: absolute;
    bottom: 3px;
    right: 510px;
    z-index: -1;
}
.blog .blog-top {
    margin-top: 55px;
}
.blog .blog-item {
    padding: 20px;
    background-color: #fff;
    border-radius: 4px;
}
.blog .blog-item .blog-img {
    overflow: hidden;
    border-radius: 8px;
}
.blog .blog-item .blog-img img {
    width: 100%;
    transition: 0.5s linear;
    position: relative;
}
.blog .blog-item .blog-inner {
    padding-top: 24px;
}
.blog .blog-item .blog-inner .blog-inner-btn {
    display: flex;
    gap: 8px;
}
.blog .blog-item .blog-inner .blog-inner-btn a {
    padding: 5px 8px;
    display: inline-block;
    background-color: #f4f4f4;
    border-radius: 6px;
    font-weight: 400;
    font-size: 14px;
    line-height: 152%;
    color: #747681;
}
.blog .blog-item .blog-inner .blog-inner-text {
    padding-top: 7px;
}
.blog .blog-item .blog-inner .blog-inner-text a {
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    color: #04091e;
    transition: 0.5s linear;
}
.blog .blog-item .blog-inner .blog-btn {
    padding-top: 8px;
}
.blog .blog-item .blog-inner .blog-btn a {
    font-weight: 400;
    font-size: 18px;
    color: #747681;
    padding-top: 8px;
}
.blog .blog-item:hover.blog-item .blog-img img {
    transform: scale(1.1);
}
.blog .blog-item:hover .blog-inner .blog-inner-text a {
    transform: scale(1.1);
    text-decoration-line: underline;
}
.blog .slick-dots button {
    background: #2662fa;
}
.blog .slick-active button {
    background: #2662fa !important;
}
.slick-dots {
    display: flex;
    justify-content: center;
    list-style-type: none;
    gap: 15px;
    margin-top: 60px;
}
.slick-dots button {
    display: block;
    width: 12px;
    height: 12px;
    padding: 0;
    border: none;
    border-radius: 100%;
    opacity: 0.2;
    text-indent: -9999px;
}
.slick-active button {
    background-color: #f4dcc0;
    opacity: 1;
    height: 14px;
    width: 14px;
}
.skills,
.skills-three {
    padding: 80px 0;
}
.skills .skill-text h2,
.skills-three .skill-text h2 {
    font-weight: 700;
    font-size: 48px;
    line-height: 67.2px;
    color: #fff;
}
.skills .skill-right p,
.skills-three .skill-right p {
    font-weight: 400;
    font-size: 24px;
    line-height: 150%;
    color: #f4f8f7;
}
.skills .skill-rigght-btn {
    margin-top: 40px;
    line-height: 0;
}
.skills .skill-rigght-btn a {
    font-family: work sans, sans-serif;
    font-size: 16px;
    font-weight: 500;
}
.skills .skill-rigght-btn a span svg {
    fill: currentColor;
    color: #fff;
}
.skills .skill-rigght-btn a:hover a span svg {
    transform: translateY(10px);
}
footer {
    padding-top: 120px;
    background: url(../public/img/footer/Footer-bg.png) center/cover no-repeat;
}

.inner-bg {
    background: url(/public/img/inner-bg.png) no-repeat center/cover;
    padding: 238px 0 116px
}

.inner-bg-head {
    text-align: center
}

.inner-bg-head h1 {
    font-weight: 700;
    font-size: 48px;
    line-height: 140%;
    color: #04091e
}

.inner-item {
    display: flex;
    align-items: center;
    gap: 17px;
    justify-content: center;
    padding-top: 8px;
    text-transform: capitalize
}

.inner-item .inner-text a {
    font-weight: 400;
    font-size: 20px;
    color: #04091e
}

.inner-item .inner-text h5 {
    font-weight: 400;
    font-size: 20px;
    color: #04091e
}

.inner-bg-two {
    background: url("/public/img/inner-bg.png") no-repeat center/cover;
    padding: 238px 0 116px
}

.inner-bg-two .inner-bg-head {
    text-align: center
}

.inner-bg-two .inner-bg-head h1 {
    color: #fff
}

.inner-bg-two .inner-text a {
    font-weight: 400;
    font-size: 20px;
    color: #fff
}

.inner-bg-two .inner-text h5 {
    font-weight: 400;
    font-size: 20px;
    color: #fff
}

.inner-bg-two .icon i {
    color: #fff;
    font-size: 20px
}



.about-hero .inner-bg-head {
    text-align: center
}

.about-hero .inner-bg-head h1 {
    font-weight: 700;
    font-size: 48px;
    line-height: 140%;
    color: #fff
}

.about-hero .inner-item {
    display: flex;
    align-items: center;
    gap: 17px;
    justify-content: center;
    padding-top: 8px;
    text-transform: capitalize
}

.about-hero .inner-item .inner-text a {
    font-weight: 400;
    font-size: 20px;
    color: #fff
}

.about-hero .inner-item .inner-text h5 {
    font-weight: 400;
    font-size: 20px;
    color: #fff
}

.about-hero .inner-item .icon i {
    color: #fff!important
}

.skills {
    padding: 80px 0;
    background: url(/public/img/skills-bg.png) no-repeat center/cover;
}
.footer-logo-item {
    border-right: 1px solid rgba(116, 118, 129, 0.3843137255);
    padding-right: 33px;
}
.footer-logo-text p {
    font-weight: 400;
    font-size: 17px;
    line-height: 152%;
    color: #fff;
    opacity: 0.8;
}
.footer-logo-icon {
    display: flex;
    gap: 10px;
    margin-top: 36px;
}
.footer-logo-icon i {
    width: 40px;
    height: 40px;
    background-color: rgba(255, 255, 255, 0.1176470588);
    border-radius: 50px;
    text-align: center;
    line-height: 40px;
    color: #747681;
    font-size: 20px;
    transition: 0.5s linear;
}
.footer-logo-icon i:hover {
    background-color: #2662fa;
    color: #fff;
    transform: translateY(-10px);
}
.footer-link-df,
.footer-services-df {
    border-right: 1px solid rgba(116, 118, 129, 0.3843137255);
    display: flex;
    justify-content: center;
}
.footer-menu,
.footer-services-menu,
.official-item {
    margin-top: 0;
}
.footer-menu ul,
.footer-services-menu ul {
    padding: 0;
    margin: 0;
}
.footer-menu ul li,
.footer-services-menu ul li {
    padding-bottom: 19px;
}
.footer-menu ul li a,
.footer-services-menu ul li a {
    font-weight: 400;
    font-size: 18px;
    color: #f5f5f5;
    transition: 0.5s linear;
}
.official {
    padding-left: 26px;
}
.official-inner {
    display: flex;
    gap: 8px;
    padding-bottom: 6px;
}
.official-inner .text a {
    font-weight: 400;
    font-size: 18px;
    color: #f5f5f5;
}
.official-text-two {
    margin-top: 24px;
}
.official-text-two p {
    font-weight: 500;
    font-size: 20px;
    color: #fff;
    padding-bottom: 16px;
}
.official-btn {
    display: flex;
}
.official-btn .official-btn-left {
    margin-right: 0;
    padding-right: 0;
}
.official-btn .official-btn-left .f-b-text {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    height: 60px;
    width: 223px;
    padding: 0 20px;
    background-color: rgba(255, 255, 255, 0.1921568627);
    border: 1px solid transparent;
    font-family: work sans, sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #fff;
}
.official-btn .official-btn-right {
    width: 128px;
    height: 60px;
    background-color: #2662fa;
    display: flex;
    align-items: center;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    transition: 0.5s linear;
}
.official-btn .official-btn-right a {
    font-weight: 400;
    font-size: 18px;
    color: #fff;
    padding-left: 26px;
    padding-right: 31px;
    transition: 0.5s linear;
}
.official-btn .official-btn-right:hover {
    background-color: #fff;
    color: #2662fa;
}
:focus-visible {
    outline: -webkit-focus-ring-color auto 0;
}
.copyright {
    padding: 22px 0;
    margin-top: 120px;
    border-top: 1px solid rgba(116, 118, 129, 0.23);
}
.copyright-text h5 {
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    color: #fff;
}
.copyright-text h5 a {
    color: #fff;
}
@media (max-width: 992px) {
    .header {
        display: none;
    }
    .mobile-header {
        display: block;
        position: fixed;
        width: 100%;
        background-color: #fff;
        z-index: 9999;
        box-shadow: 5px 6px 60px rgba(17, 29, 59, 0.04);
    }
    #offCanvas-nav .m-nav {
        position: fixed;
        height: 100%;
        z-index: 9999;
    }
}
@media (max-width: 1024px) {
    .grid-item,
    .grid-sizer {
        width: calc(50% - 20px);
    }
}
@media (max-width: 446px) {
    .grid-item,
    .grid-sizer {
        width: 100%;
    }
}
@media (max-width: 767px) {
    .experience .experience-inner-two {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 !important;
        padding-top:10px !important;
        padding-bottom:10px !important;
        padding-left: 0 !important;
        border-bottom: 1px solid #dcdeefb0;
    }
    .accordion-form .form .form-btn,
    .project-btn {
        line-height: 0;
    }
    .customers .customer-head-inner .customer-head-innner-df .customer-head-inner-text h4 {
        font-size: 22px;
    }
    .container {
        width: 100%;
        padding: 0 20px;
    }
    .award-image img,
    .banner-img img,
    .blog-details-img img,
    .error-img img,
    .faq,
    .official-btn .official-btn-left .f-b-text,
    .portfolio-detalis-img img,
    .product-details-img img,
    .service-heading-img img,
    .services-details-img img,
    .share-img img,
    .student-video-main img,
    .team-img img,
    .warren-img img,
    .warren-right-img img {
        width: 100%;
    }
    #tab,
    .about,
    .coming-soon,
    .customer-4,
    .error,
    .gallery,
    .h-1-blog,
    .lawyer-award,
    .lawyer-gallary,
    .lawyer-news,
    .news,
    .photogallary,
    .photographer-service,
    .portfolio-detalis,
    .product,
    .product-details,
    .service-4,
    .service-testimonial,
    .skills,
    .student,
    .team,
    .thought {
        padding: 60px 0;
    }
    .error-text h2 {
        font-size: 38px;
    }
    .banner {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .Product-vic,
    .Project,
    .about-4 .about-heading-img,
    .about-two-img .about-two-img-vic,
    .about-victor,
    .accordion-form .accordion-tittle .accordion-tittle-img,
    .article,
    .banner-two .banner-text h1::before,
    .banner-vic,
    .coming-soon-item span,
    .comming-soon-img .comming-soon-img-2,
    .contuict-me-main::after,
    .customer-head-inner-left img,
    .experience .experience-inner .experience-btn a,
    .experience .experience-inner-two .experience-btn a,
    .featured .featured-head h4::before,
    .freelance-item .freelance-img::before,
    .hero .hero-content .hero-content-img,
    .hero .hero-content .hero-testimonial-img,
    .hero .hero-content .hero-victor-img,
    .hero .hero-img .chart,
    .hero .hero-img .congratulation,
    .hero .hero-img .students,
    .hero-content-imgg,
    .hero-tittle-img,
    .hero-vic,
    .lawyear-testimonial,
    .lawyer-footer .footer-top,
    .news .news-tittle .news-tittle-img,
    .position-img,
    .process-item-two,
    .progresss-bar,
    .project-bg::before,
    .resume-head-left,
    .resume-skill,
    .service-4 .service-title .service-inner-text img,
    .service-details-4 .service-heading,
    .service-heading-img,
    .service-social-icon p,
    .service-vic,
    .skill-btn,
    .student .student-heading h2 img,
    .tab-head h2::after,
    .tab-item .tab-img .tab-img-overlay .tab-img-overlay-text h5::before,
    .tab-item .tab-img .tab-img-overlay .tab-img-overlay-text p::before,
    .thought-vic {
        display: none;
    }
    .contuict-me-main {
        display: contents;
    }
    .banner-btn-item,
    .coming-soon-item,
    .freelance-item {
        display: flex;
        display: flex;
    }
    .contact-me {
        padding: 60px 0 0;
        margin-bottom: -17px;
    }
    .inner-bg {
        padding: 115px 0 60px;
    }
    .coming-soon-item {
        gap: 6px;
        flex-wrap: wrap;
        justify-content: center;
    }
    .coming-soon-text h2 {
        font-weight: 600;
        font-size: 38px;
    }
    .official-btn-left-two {
        width: 200px;
    }
    .featured-item,
    .footer-item,
    .service-item,
    .team-item {
        margin-bottom: 30px;
    }
    .freelance-item {
        gap: 60px;
        flex-wrap: wrap;
        margin-top: 40px;
        gap: 28px;
        flex-wrap: wrap;
        justify-content: center;
    }
    .contuict-me-main .contact-me-item .contact-me-inner .icon,
    .portfolio-item ul li,
    .resume-title {
        margin-bottom: 20px;
    }
    .resume-skill {
        align-items: center;
        gap: 81px;
        margin-top: 50px;
        padding-bottom: 7px;
    }
    .about-4,
    .about-photographer,
    .banner-two,
    .featured,
    .footer-five,
    .footer-four,
    .footer-three,
    .footer-two,
    .service-details,
    .service-details-4,
    .services-details {
        padding: 60px 0 0;
    }
    .banner-text h1 {
        font-size: 35px;
        line-height: 50px;
        text-align: center;
        justify-content: center;
    }
    .about-text p,
    .banner-text p {
        font-size: 16px;
        text-align: center;
    }
    .banner-text p {
        font-weight: 400;
        line-height: 155%;
        padding-top: 15px;
        margin: 0;
    }
    .about-text p {
        line-height: 152%;
        padding-top: 24px;
    }
    .banner-two .banner-btn-text,
    .service-two .slick-dots {
        margin-top: 40px;
    }
    .customer-head h2 {
        font-size: 38px;
        line-height: 55.2px;
    }
    .about-two-text,
    .customer-head,
    .featured .featured-btn {
        text-align: center;
        margin-top: 40px;
    }
    .signature {
        margin-top: 57px;
        text-align: center;
    }
    .customers .customers-item .customers-inner .customers-img img {
        width: 87%;
    }
    .customers .customer-head,
    .customers .customers-text,
    .send {
        margin-top: 50px;
    }
    .customers .customer-head h2,
    .service-4 .service-title h2 {
        font-size: 25px;
    }
    .banner-btn-item {
        gap: 36px;
        justify-content: center;
    }
    .project-bg {
        background-color: #fff !important;
        margin-top: 0 !important;
        border: 1px solid transparent;
    }
    .blog-two {
        margin-top: -327px;
    }
    .footer-two .footer-item,
    .lawyer-form .contact-form,
    .query-img {
        margin-bottom: 50px;
    }
    .blog .blog-head h2,
    .blog-head h2 {
        font-size: 32px;
        padding: 0;
    }
    .accordion-form .form,
    .blog .blog-top,
    .experience,
    .gallery-item-02,
    .service-qury-btn {
        margin-top: 30px;
    }
    .Project-btn,
    .about-4 .about-description .about-testimonial,
    .about-content,
    .award .award-heading h3,
    .customer-heading,
    .experience-text,
    .featured-head,
    .freelance-head h3,
    .lawyer-award .award-heading h3,
    .lawyer-award .award-heading p,
    .photogallary .photogallary-heading h3,
    .photographer-service .service-tittle p,
    .resume-head-p p,
    .send .send-item .send-btn,
    .service-blog,
    .skill-rigght-btn,
    .team .team-item .team-text,
    h2 {
        text-align: center;
    }
    .banner-img .banner-item {
        position: unset;
    }
    .banner-btn-left .btn-style-1 {
        width: 120px;
    }
    .about-text h2 {
        font-size: 29px;
        text-align: center;
    }
    .about-lawyer,
    .about-two,
    .accordion-form,
    .award,
    .photographer-form,
    .resume,
    .service {
        padding: 0 0 60px;
    }
    .banner-text {
        margin-top: 60px;
    }
    .Project-head-middle .Project-head-middle-item .text ul li,
    .accordion-button,
    .banner-two .banner-btn-text h4,
    .blog-two .blog-item .blog-inner .blog-inner-btn a,
    .official-inner .text a {
        font-size: 16px;
    }
    .customers .slick-dots button,
    .h-1-blog .blog-item,
    .service .slick-dots {
        margin: 0;
    }
    .about-lawyer .about-checkpoint,
    .footer-four .official-btn-right,
    .resume-head,
    .team .h-1-blog-btn,
    .warren-item .warren-inner {
        justify-content: center;
    }
    .experience .experience-inner {
        padding: 0 0 17px;
        justify-content: start;
    }
    .customers-item .customers-inner .customers-img,
    .footer-logo-icon,
    .official-btn,
    .tools-btn {
        justify-content: center;
        display: flex;
    }
    .resume-main {
        padding: 60px 60px 51px !important;
    }
    .slick-dots button {
        margin-bottom: 0 !important;
    }
    .skill-btn {
        gap: 28px;
    }
    .tools-btn {
        margin-right: 0;
        gap: 23px;
    }
    .comment-text,
    .hero-tittle,
    .tab-item {
        margin-top: 20px;
    }
    .process-item-text {
        display: flex;
        gap: 200px;
    }
    .process-item-text-one::after,
    .process-item-text-two::after,
    .process-item-text::after {
        width: 331px;
    }
    .process-item-text::before {
        width: 297px;
    }
    .process-item-text-one {
        display: flex;
        gap: 100px;
    }
    .process-item-text-one::before {
        position: absolute;
        width: 220px;
    }
    .process-item-text-two {
        display: flex;
        gap: 70px;
    }
    .process-item-text-two::before {
        position: absolute;
        width: 260px;
    }
    .service-head h2,
    .thought-text h2 {
        font-size: 32px;
        text-align: center;
    }
    .Project-two {
        background: 0 0 !important;
        padding: 0;
        margin: 0 !important;
    }
    .Project-head p {
        text-align: center;
        font-size: 16px;
    }
    .Project-right-top {
        margin-top: 100px;
    }
    .Product {
        padding: 60px 0;
        margin-top: 0;
    }
    .Project-head h2 {
        font-size: 25px;
        text-align: center;
    }
    .Product-head h2 {
        text-align: center;
        font-size: 40px;
        line-height: 50px;
    }
    .portfolio-gallery,
    .res-mt {
        margin-top: 80px;
    }
    .Product-item {
        position: relative;
        margin-top: 30px;
    }
    .blog {
        padding: 50px 0;
    }
    .skill-text h2 {
        font-size: 32px;
        text-align: center;
        line-height: 42.2px;
    }
    .skill-right {
        margin-top: 25px;
        text-align: center;
    }
    .about-two-text h2,
    .service-item .service-item-text h3 {
        font-size: 24px;
    }
    .footer-logo-item {
        border-right: 1px solid transparent;
        padding-right: 0;
        text-align: center;
    }
    .footer-link-df,
    .footer-services-df {
        border-right: 1px solid transparent;
        text-align: center;
        margin-top: 40px;
    }
    .official {
        margin-top: 40px;
        text-align: center;
    }
    .official-inner {
        display: flex;
        gap: 8px;
        justify-content: center;
    }
    .banner-img,
    .copyright {
        margin-top: 70px;
    }
    .blog-item .blog-inner .blog-inner-text a {
        font-size: 14px;
        line-height: 150%;
    }
    footer {
        padding-top: 60px;
    }
    .service-text {
        padding-top: 20px;
        text-align: center;
    }
    .send .send-item .send-inner {
        display: inline-grid;
        gap: 20px;
        width: 100%;
    }
    .about-two-img img {
        width: 100%;
        position: unset;
        margin-top: 60px;
    }
    .course-filter {
        display: flex;
        flex-wrap: wrap;
    }
    .about-two-btn,
    .project-4 .project-section .project-content .project-btn,
    .warren-contact {
        margin-top: 30px;
        text-align: center;
    }
    .about-two-text span,
    .accordion-body p,
    .project-list p,
    .text p {
        font-size: 14px;
    }
    .lawyer-gallary .lawyear-gallary-gap {
        padding-top: 24px;
    }
    .lawyer-form,
    .thought-three {
        padding: 0 !important;
    }
    .lawyer-form .contact-form .from-iitem,
    .lawyer-service-details .service-blog .service-research .service-research-img,
    .service-details .service-blog .service-research .service-research-img,
    .service-details-4 .service-blog .service-research .service-research-img,
    .service-details-photographer .service-blog .service-research .service-research-img {
        display: block;
    }
    .about-two-text p {
        font-size: 18px;
        padding-right: 0;
        padding-top: 18px;
    }
    .banner-two .banner-btn-text h4 span {
        font-size: 27px;
    }
    .slick-dots {
        margin: 30px 0 0;
    }
    .about-lawyer .signature,
    .lawyer-service .service-tittle,
    .project-4,
    .slick {
        padding: 0;
    }
    .tab-head h2 {
        font-size: 32px;
    }
    .featured .featured-head h2,
    .research h2,
    .website .website-text h2 {
        font-size: 28px;
    }
    .about-lawyer .about-img,
    .comment .comment-item .comment-inner-two,
    .featured .featured-head h4 {
        margin-left: 0;
    }
    .h-1-blog .blog-head h2 {
        font-size: 35px;
        padding: 0;
    }
    .Product-top,
    .h-1-blog .b-h-1-mt,
    .inner-mt,
    .photographer-service .service-card-bottom,
    .research-top-text,
    .service-three .ser-mt {
        margin-top: 0;
    }
    .blog-details-article-head h2 {
        font-size: 25px;
        text-align: center;
        line-height: 35px;
    }
    .comment .comment-item .comment-inner,
    .comment .comment-item .comment-inner .comment-text .text,
    .product-box .product-sarch {
        display: flow-root;
    }
    .resume-head .resume-head-left h5 {
        font-size: 11px;
    }
    .resume-head .resume-head-left p {
        font-size: 10px;
    }
    .thought-two {
        padding: 1573px 0 120px;
    }
    .about-hero .inner-bg-head h1,
    .inner-bg-head h1,
    .lawyer-hero .inner-bg-head h1,
    .lawyergallary-heading h2 {
        font-size: 40px;
    }
    .services-details-article-icon {
        display: inline-grid;
    }
    .research-img img {
        width: 100%;
        margin-bottom: 20px;
    }
    .query-img .query-btn {
        text-align: center;
        margin-top: 30px;
    }
    .query-img .query h2 {
        font-size: 28px;
        text-align: center;
    }
    .portfolio-detalis-head h2 {
        font-weight: 600;
        font-size: 33px;
    }
    .product-box .product-sarch .product-btn .product-btn-left .p-b-text {
        width: 210px;
    }
    .product-box .product-sarch .product-btn .product-btn-right a {
        width: 93px;
    }
    .product-box {
        display: flow-root;
        justify-content: space-between;
    }
    .results {
        text-align: center;
        margin-top: 20px;
    }
    .product-box .product-grid {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }
    .text-end {
        text-align: center !important;
        margin-top: 30px;
    }
    .photographer-about .about-btn {
        margin-top: 7px;
        text-align: center;
    }
    .product-details-text h2 {
        text-align: center;
        font-size: 28px;
    }
    .official,
    .product-details-text-right p span {
        padding-left: 0;
    }
    .additional-details-item {
        display: flow-root;
        gap: 66px;
        margin-top: 24px;
    }
    .warren-top {
        padding: 40px 27px !important;
    }
    .blog .blog-item .blog-inner .blog-inner-text a,
    .news-text h3,
    .warren-item .warren-inner .text a {
        font-size: 20px;
    }
    .warren-btn {
        margin-bottom: 30px;
        justify-content: center;
    }
    .hero .hero-content {
        padding: 120px 0 0;
    }
    .hero .hero-content h1 {
        font-size: 30px;
        line-height: 45.46px;
        text-align: center;
    }
    .hero-inner-img img {
        width: 100%;
        display: none;
    }
    .hero .hero-img {
        width: 100%;
        height: 100%;
    }
    .hero .hero-img .hero-inner-img {
        position: unset;
        margin-top: 50px;
    }
    .hero .hero-content p {
        padding: 10px 0;
        font-size: 14px;
        line-height: 30px;
        text-align: center;
    }
    .project-4 .project-section .project-content .project-list li p {
        font-size: 13px;
    }
    .Project-pt,
    .about-4 .about-content h3,
    .photographer-about {
        padding-top: 0;
    }
    .about-4 .about-content h2 {
        font-size: 28px;
        line-height: 45.46px;
    }
    .about-4 .about-content p {
        font-size: 14px;
        line-height: 27.36px;
    }
    .about-img img {
        width: 100%;
        margin-top: 50px;
    }
    .service-4 .service-title h3 {
        padding-bottom: 10px;
    }
    .project-tittle h2 {
        font-size: 25px;
        line-height: 40.2px;
        text-align: center;
    }
    .project-4 .project-section {
        padding: 30px;
        margin-top: 52px;
    }
    .project-tittle p {
        font-size: 14px;
        text-align: center;
    }
    .project-btn {
        margin-bottom: 30px;
    }
    .student .student-heading h2 {
        font-size: 25px;
        line-height: 43.2px;
    }
    .student .student-video {
        margin: 20px 0 0;
    }
    .customer-heading h2 {
        font-size: 30px;
        line-height: 40.2px;
    }
    .award-img img,
    .customer-img img {
        width: 100%;
        margin-top: 30px;
    }
    .accordion-tittle h2 {
        text-align: center;
        font-size: 30px;
        line-height: 43px;
    }
    .accordion-header h3 {
        font-size: 18px;
    }
    .accordion-header,
    .skills .skill-right p {
        font-size: 19px;
    }
    .accordion-form .accordion-flush .accordion-item .accordion-body {
        padding: 0 27px 26px;
    }
    .news-tittle h2 {
        font-size: 30px;
        line-height: 43px;
    }
    .news .row,
    .service-head {
        padding: 30px 0 0;
    }
    .footer-four .official-btn-right a {
        padding-left: 3px;
        padding-right: 4px;
    }
    .service-4 .service-card,
    .service-testimonial .service-card {
        margin: 0 0 32px;
    }
    .about-hero,
    .photographer-hero {
        padding: 160px 0 100px;
    }
    .photographer-hero .inner-bg-head h1 {
        font-size: 42px;
    }
    .service-details-4 .service-heading .service-social-detail {
        padding: 0 0 20px;
        text-align: center;
    }
    .service-blog-tittle h2 {
        font-size: 25px;
        line-height: 45.2px;
        text-align: center;
    }
    .lawyer-service-details .service-blog .service-research .service-research-img img,
    .service-details .service-blog .service-research .service-research-img img,
    .service-details-4 .service-blog .service-research .service-research-img img {
        width: 100%;
        padding-bottom: 20px;
    }
    .lawyer-award .award-heading h2,
    .service-details .service-blog .service-research .service-research-img img:last-child,
    .service-details-4 .service-blog .service-research .service-research-img img:last-child {
        padding-bottom: 0;
    }
    .service-query-content h2 {
        font-size: 19px;
        line-height: 40px;
        text-align: center;
    }
    .about-4 .about-description {
        padding-top: 10px;
    }
    .hero-achievement-img {
        text-align: center;
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .hero-achievement-img img,
    .portfolio-4 .portfolio-heading h3 {
        padding-bottom: 20px;
    }
    .about-photographer .about-btn,
    .lawyer-service .container {
        margin-top: 0;
        text-align: center;
    }
    .lawyer-service .service-card-wrapper .service-card,
    .photographer-service .service-card {
        margin-top: 20px;
        margin-right: 0;
    }
    .service-heading h2 {
        font-size: 30px;
        line-height: 48.2px;
    }
    .portfolio-4 {
        padding: 60px 0;
        display: none;
    }
    .portfolio-heading h2 {
        font-size: 33px;
        line-height: 48.2px;
    }
    .portfolio-4 .next-arrow,
    .portfolio-4 .prev-arrow {
        position: absolute;
        top: -95px;
    }
    .photogallary .photogallary-gap .photogallary-box {
        margin-right: 0;
    }
    .photographer-form .hire-content h2 {
        font-size: 30px;
        line-height: 38px;
    }
    .research-top-text ul li {
        padding: 20px 0 0;
    }
    .award-heading h2 {
        font-size: 26px;
        line-height: 36px;
        padding-bottom: 30px;
    }
    .photogallary-heading h2 {
        font-size: 22px;
        line-height: 40.2px;
    }
    .hire-content h2 {
        font-size: 38px;
        line-height: 48.2px;
    }
    .about-content h2 {
        font-size: 32px;
        line-height: 42px;
    }
    .photographer-about .about-content h3 {
        padding-top: 30px;
    }
    .hero {
        min-height: 400px;
        padding-bottom: 0;
    }
    .hero .hero-content .hero-btn-1 {
        text-align: center;
        margin-bottom: 50px;
    }
    .footer-main-item-four {
        padding: 32px 0 0;
    }
    .lawyer-footer .footer-center .footer-official .office-address .official-btn input {
        height: 60px;
        width: 100%;
    }
    .lawyer-service .container {
        padding: 56px 30px 64px;
    }
    .about-lawyer .about-content h3 {
        padding-top: 20px;
    }
    .lawyer-gallary .lawyear-gallary-gap .lawyergallary-box {
        margin-right: 0;
        margin-bottom: 20px;
    }
    .lawyer-gallary .lawyear-gallary-gap .lawyergallary-box .lawyergallary-content {
        padding: 19px 0;
        text-align: center;
    }
    .hire-content {
        padding-bottom: 30px;
        padding-top: 50px;
        text-align: center;
    }
    .lawyer-hero {
        padding: 148px 0 92px;
    }
    .lawyer-service-details .service-heading .service-social-icon {
        gap: 13px;
    }
    .service-query-3 .service-query-section .service-query-box .service-qury-btn .btn-style-4 {
        width: 184px;
    }
    .project-bg {
        padding: 62px 0 !important;
    }
    .skills .skill-text h2 {
        font-size: 33px;
        line-height: 43.2px;
    }
    .coming-soon-item .coming-soon-inner {
        width: 100%;
        text-align: center;
    }
    .contuict-me-main .contact-me-item .contact-me-inner {
        display: block;
        text-align: center;
        margin-bottom: 40px;
    }
    .customers .customers-text h2 {
        line-height: 40px;
    }
    .inner-service .service-item {
        margin: 0 0 20px;
    }
    .b-slick {
        margin-top: 90px;
    }
    .slick .slick-item .slick-text h3 {
        padding-right: 0;
    }
    .service-details-photographer .service-blog .service-research .service-research-img img {
        display: block;
        width: 100%;
        margin-bottom: 30px;
    }
    .service-details-photographer .service-blog .service-research .service-research-img img:last-child {
        margin-bottom: 0;
    }
    .service-query .service-query-section .service-query-content h2 {
        font-size: 18px;
        line-height: 33px;
    }
    .service-two .service-item-two {
        margin: 0 0 30px;
    }
    .customers {
        padding: 0 0 60px !important;
    }
    .blog-two .blog-head {
        margin-top: 320px;
    }
    .product-head h2 {
        font-size: 30px;
        line-height: 48px;
    }
    .similar-text h3 {
        margin-bottom: -103px;
    }
    .photographer-about .about-img .about-inner-img {
        margin-left: 0 !important;
    }
    .service-tittle p {
        font-size: 17px;
    }
    .lawyer-service .service-card-wrapper .service-card-2 {
        padding: 40px 24px;
    }
    .lawyer-news .news-section {
        margin-right: 0;
        margin-bottom: 30px;
    }
    .slick-two .slick-item-two {
        margin: 30px 0;
    }
    .portfolio {
        padding: 30px 0 0;
        background-color: #050f25;
    }
}
@media (min-width: 768px) and (max-width: 991.98px) {
    .experience .experience-inner-two {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0 17px;
        border-bottom: 1px solid #dcdeefb0;
    }
    .footer-link-df,
    .footer-logo-item,
    .footer-services-df {
        border-right: 1px solid transparent;
    }
    .Project-btn,
    .about-text h2,
    .banner-text,
    .customer-heading,
    .hero-content,
    .portfolio-item,
    .service-blog-tittle h2,
    .service-head h2,
    .service-text p,
    .signature,
    .skills .skill-rigght-btn {
        text-align: center;
    }
    .query-img {
        margin-bottom: 40px;
    }
    .portfolio-item {
        margin-bottom: 20px;
    }
    .about-img img,
    .additional-details-slick img,
    .award-image img,
    .banner-img img,
    .blog-details-img img,
    .faq,
    .portfolio-detalis-img img,
    .product-details-img img,
    .research-img img,
    .service-heading-img img,
    .services-details-img img,
    .share-img img,
    .student-video-main img,
    .team-img img,
    .warren-img img,
    .warren-right-img img {
        width: 100%;
    }
    .services-details-article-icon .text p {
        font-size: 15px;
    }
    .about-photographer,
    .service-details,
    .services-details {
        padding: 80px 0 0;
    }
    .service-mt {
        padding: 0 !important;
    }
    .query-img .query h2 {
        font-size: 28px;
    }
    .about,
    .banner,
    .blog,
    .coming-soon,
    .customers,
    .featured,
    .gallery,
    .h-1-blog,
    .lawyer-news,
    .lawyer-service-2 .container,
    .news,
    .photogallary,
    .photographer-form,
    .photographer-service,
    .portfolio-detalis,
    .product,
    .product-details,
    .service-4,
    .service-testimonial,
    .team,
    .thought {
        padding: 80px 0;
    }
    .website .website-text h2 {
        font-size: 42px;
    }
    .contuict-me-main {
        padding: 60px 50px;
    }
    .Project,
    .about-4 .about-heading-img,
    .about-description,
    .about-two-img .about-two-img-vic,
    .banner-two .banner-text h1::before,
    .banner-vic,
    .banner-vic-1,
    .blog .blog-vic,
    .chart,
    .comming-soon-img .comming-soon-img-2,
    .contuict-me-main::after,
    .customer-head-inner-left,
    .dis-none,
    .h-1-blog .blog-vic,
    .hero .hero-content .hero-victor-img,
    .hero-content-imgg,
    .hero-inner-img img,
    .hero-vic,
    .lawyear-testimonial,
    .lawyer-award,
    .lawyer-footer .footer-top,
    .portfolio-4,
    .position-img,
    .progresss-bar,
    .project-bg::before,
    .service-vic,
    .thought-text,
    .thought-vic,
    .warren-ml {
        display: none;
    }
    .inner-bg {
        padding: 138px 0 80px;
    }
    .about-lawyer .about-checkpoint,
    .coming-soon-item {
        justify-content: center;
    }
    .freelance-item {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .resume-skill {
        display: flex;
        gap: 0;
        justify-content: space-between;
    }
    .banner-btn-item,
    .process-item {
        justify-content: center;
        display: flex;
    }
    .tools-btn {
        margin-top: 74px;
        margin-right: 0;
    }
    .banner-img .banner-item {
        position: unset;
    }
    .Product {
        padding: 60px 0;
        margin-top: 0;
    }
    .footer,
    .photographer-about {
        padding-top: 80px;
    }
    .award .award-image,
    .blog-inner-text {
        margin-top: 15px;
    }
    .blog-item .blog-inner .blog-inner-text a {
        font-size: 16px;
        line-height: 150%;
    }
    .banner-text h1 {
        font-weight: 700;
        font-size: 54px;
        line-height: 70px;
    }
    .banner-text {
        margin-top: 50px;
    }
    .banner-two .banner-btn-text {
        margin-top: 30px;
        text-align: center;
        padding-bottom: 40px;
    }
    .about-two-img img {
        position: unset;
        width: 100%;
    }
    .about-two-text,
    .customers .customer-head,
    .official,
    .send,
    .warren-img-top {
        margin-top: 50px;
    }
    .process-item-two .process-item-text .process-over,
    .process-item-two .process-item-text-two .process-over {
        right: 286px;
    }
    .process-item-two .process-item-text-one .process-over {
        right: 309px;
    }
    .tab-head h2::after {
        right: 64px;
    }
    .tab-item {
        margin-top: 20px;
    }
    .signature {
        margin-top: 50px;
    }
    .customers .customers-item .customers-inner .customers-img img {
        width: 80%;
    }
    .project-bg {
        margin-top: 113px !important;
    }
    .about-text .about-victor {
        right: 255px;
        top: 68px;
    }
    .service {
        padding: 90px 0 0;
    }
    .Project-two {
        padding: 0;
        margin: 0 !important;
        background: 0 0 !important;
    }
    .footer-main-item .footer-pl {
        padding: 0 15px 30px;
    }
    .footer-main-item {
        padding: 32px 0 0;
    }
    .Project-right-top {
        margin-top: 100px;
    }
    .Product-vic {
        position: absolute;
        top: -4px;
        right: 45px;
    }
    .about-lawyer .signature,
    .accordion-form,
    .blog .blog-head h2 {
        padding: 0;
    }
    .thought-text h2 {
        font-size: 48px;
    }
    .blog-head h2 {
        padding: 0 74px;
    }
    .blog-vic {
        position: absolute;
        bottom: 5px;
        right: 286px;
        z-index: -1;
    }
    .footer-logo-item {
        padding-right: 0;
    }
    .footer-link-df {
        display: flex;
        justify-content: center;
    }
    .footer-services-df {
        display: flex;
        justify-content: start;
        margin-top: 50px;
    }
    .accordion-form .form,
    .copyright {
        margin-top: 70px;
    }
    .award,
    .banner-two,
    .service-two {
        padding: 0 0 80px;
    }
    .h-1-blog .blog-head h2 {
        padding: 0 116px;
    }
    .h-1-blog .h-1-mt,
    .inner-mt,
    .research-top,
    .service-three .ser-mt,
    .team .team-mt {
        margin-top: 0;
    }
    .h-1-blog .blog-item,
    .service-two .service-item-two,
    .team-item {
        margin-bottom: 20px;
    }
    .Project-btn {
        margin-top: 45px;
    }
    .product-box .product-sarch .product-btn .product-btn-left .p-b-text {
        width: 199px;
    }
    .product-box .product-sarch .product-btn .product-btn-right a {
        width: 100px;
    }
    .customers .slick-dots {
        margin: 60px 0 -116px;
    }
    .product-details-text-right-icon {
        justify-content: start;
        margin-left: 0;
    }
    .product-details-text h2 {
        font-size: 32px;
    }
    .text-end {
        text-align: right !important;
        text-align: start !important;
    }
    .product-details-text-right p span {
        padding-left: 0;
    }
    .additional-pr {
        padding: 0 15px;
    }
    .warren-top {
        padding: 40px 27px !important;
    }
    .project-bg {
        padding: 8px 64px 40px;
    }
    .blog-two {
        margin-top: -349px;
    }
    .tab-item .tab-img .tab-img-overlay .tab-img-overlay-text p::before {
        left: 108px;
    }
    .tab-item .tab-img .tab-img-overlay .tab-img-overlay-text h5::before {
        left: 106px;
    }
    .container {
        width: 100%;
        padding: 0 20px;
    }
    .hero .hero-img .hero-inner-img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
    }
    .hero .hero-content {
        padding: 140px 0 100px;
    }
    .about-4,
    .about-lawyer,
    .lawyer-gallary,
    .student {
        padding: 60px 0;
    }
    .about-4 .about-content h2 {
        font-size: 30px;
        line-height: 43.8px;
    }
    .project-4 .project-section .project-content .project-btn {
        line-height: 0;
        margin-bottom: 50px;
    }
    .customer-4,
    .project-4 {
        padding: 60px 0 0;
    }
    .customer-img img {
        width: 100%;
        margin-top: 30px;
    }
    .accordion-form .accordion-tittle .accordion-tittle-img {
        right: 299px;
    }
    .news .row {
        padding: 50px 0 0;
    }
    .about-hero {
        padding: 120px 0 58px;
    }
    .official-btn .official-btn-left .f-b-text {
        width: 200px;
    }
    .service-details .service-blog .service-research .service-research-img {
        display: block;
    }
    .service-details .service-blog .service-research .service-research-img img {
        width: 100%;
        padding-bottom: 30px;
    }
    .service-details .service-blog .service-research .service-research-img img:last-child {
        padding-bottom: 0;
    }
    .service-blog-tittle h2 {
        font-size: 46px;
    }
    .service-qury-btn {
        text-align: end;
    }
    .service-query-content h2 {
        font-size: 24px;
        line-height: 45.2px;
    }
    .about-4 .about-content h3 {
        padding-top: 0;
        padding-bottom: 20px;
    }
    .service-4 .service-card {
        margin: 0 0 32px;
    }
    .about-content,
    .award-btn,
    .photographer-about .about-btn,
    .skills {
        margin-top: 30px;
    }
    .photographer .hero-img {
        height: 0;
    }
    .service-heading h2 {
        font-size: 34px;
    }
    .hire-content h2 {
        font-size: 32px;
        line-height: 54.2px;
    }
    .about-content h2 {
        font-size: 36px;
        line-height: 55px;
    }
    .photographer-hero {
        padding: 160px 0 100px;
    }
    .service-research-img img {
        width: 47%;
    }
    .Project-pt,
    .service-btn {
        padding-top: 0;
    }
    .lawyer .hero-img {
        height: 100%;
    }
    .about-lawyer .about-img {
        margin-left: 0;
    }
    .about-content {
        text-align: center;
    }
    .lawyer-gallary .lawyear-gallary-gap .lawyergallary-box {
        margin-bottom: 20px;
        margin-right: 0;
    }
    .lawyer-gallary .lawyear-gallary-gap .lawyergallary-box .lawyergallary-content {
        padding: 19px 0;
        text-align: center;
    }
    .lawyer-hero {
        padding: 172px 0 133px;
    }
    .service-heading {
        padding-left: 20px;
    }
    .banner-img {
        margin-top: 60px;
    }
    .customers {
        padding: 80px 0 96px !important;
    }
    .skills .skill-text h2 {
        line-height: 56.2px;
        padding-right: 164px;
    }
    .skills .skill-right p {
        padding-top: 30px;
        padding-right: 160px;
    }
    .contuict-me-main .contact-me-item .contact-me-inner {
        display: block;
        text-align: center;
    }
    .inner-service .service-item {
        margin: 0 0 20px;
    }
    .blog-two .blog-head {
        margin-top: 321px;
    }
    .slick .slick-item .slick-text h3 {
        padding-right: 0;
    }
    .slick-two .mar {
        padding: 0 15px;
        margin-top: 0;
    }
    .slick-item {
        margin-bottom: 30px;
    }
    .slick-two {
        padding: 126px 0 0;
        margin-top: 0;
    }
    .slick-01 {
        margin-top: 6px;
        padding-bottom: 0;
    }
    .research-img {
        margin: 30px 0 0;
    }
    .skills-three {
        margin: 0;
    }
    .hero {
        min-height: 650px;
    }
    .hero .hero-img {
        width: 100%;
        height: 100%;
    }
    .photographer-about .about-img .about-inner-img {
        margin-left: 0 !important;
    }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
    .about-img img,
    .additional-details-slick img,
    .award-image img,
    .award-img img,
    .banner-img img,
    .blog-details-img img,
    .customer-img img,
    .faq,
    .hero-inner-img img,
    .portfolio-detalis-img img,
    .product-details-img img,
    .research-img img,
    .service-heading-img img,
    .service-research-img img,
    .services-details-img img,
    .share-img img,
    .student-video-main img,
    .warren-img img {
        width: 100%;
    }
    .service-research-img {
        display: none !important;
    }
    .about-two-img .about-two-img-vic,
    .award .award-image .award-img-heading,
    .banner-vic,
    .banner-vic-1,
    .comming-soon-img .comming-soon-img-2,
    .dis-none,
    .hero .hero-img .congratulation,
    .hero .hero-img .students,
    .hero-content-img,
    .lawyer-gallary .lawyear-gallary-gap .lawyergallary-box .lawyergallary-img .lawyergallary-social-icon,
    .news-tittle-img,
    .photogallary-social-icon,
    .process-item-two,
    .thought {
        display: none;
    }
    .research-top {
        margin-top: 0;
    }
    .about-text h2,
    .accordion-tittle h2,
    .hire-content h2,
    .query-img .query h2,
    .service-head h2,
    .thought-text h2 {
        font-size: 40px;
    }
    .blog-details-article-head h2 {
        font-size: 44px;
    }
    .about-lawyer,
    .blog,
    .coming-soon,
    .lawyear-testimonial,
    .news,
    .photographer-form,
    .photographer-service,
    .product-details,
    .service {
        padding: 100px 0;
    }
    .freelance-item {
        gap: 26px;
    }
    .footer-item,
    .footer-main-item .footer-item,
    .portfolio-item ul li {
        padding: 40px 0;
    }
    .menu-bg .nav-main .menu ul {
        gap: 25px;
    }
    .menu-bg .nav-main .menu-main {
        gap: 18px;
    }
    .banner {
        padding: 220px 0 66px;
    }
    .banner-img .banner-item {
        position: unset;
    }
    .blog-vic,
    .position-img,
    .service-vic,
    .thought-vic {
        position: absolute;
    }
    .banner-text h1 {
        font-size: 50px;
        margin-bottom: 0;
        line-height: 70px;
    }
    .banner-two .banner-text h1::before {
        top: 178px;
        left: 3px;
        width: 398px;
        height: 8%;
    }
    .about-two-img img {
        position: unset;
        left: -241px;
        top: 0;
        width: 100%;
    }
    .project-tittle h2,
    .tab-head h2 {
        font-size: 42px;
    }
    .tab-head h2::after {
        right: 16px;
        width: 198px;
    }
    .about-two-text h2 {
        font-weight: 700;
        font-size: 38px;
    }
    .about-2-two {
        align-items: center;
    }
    .position-img .about-img .about-overlay {
        bottom: 13px;
        right: 83px;
    }
    .about-text .about-victor {
        position: absolute;
        top: -8px;
        right: 0;
    }
    .accordion-button,
    .blog .blog-item .blog-inner .blog-inner-text a,
    .blog-three .blog-item .blog-inner .blog-inner-text a,
    .customer-head-inner .customer-head-innner-df .customer-head-inner-text h4,
    .customers .customer-head-inner .customer-head-innner-df .customer-head-inner-text h4,
    .h-1-blog .blog-item .blog-inner .blog-inner-text a,
    .news-text h3,
    .service-two .service-item-two .service-item-text h3 {
        font-size: 20px;
    }
    .service-vic {
        top: -10px;
        right: -1px;
        z-index: -1;
    }
    .service-item .service-item-text h3 {
        font-size: 23px;
    }
    .skills .skill-text h2 {
        font-size: 40px;
        line-height: 55.2px;
    }
    .lawyer-service .service-card-wrapper .service-card,
    .tools-btn {
        margin-right: 0;
    }
    .Project-right-item-text p,
    .fa-solid p,
    .team-item .team-text p {
        font-size: 14px;
    }
    .Product-vic {
        right: 160px;
    }
    .blog .blog-vic {
        right: 300px;
    }
    .about-lawyer .signature,
    .blog-three .blog-head h2 {
        padding: 0;
    }
    .blog-head h2 {
        padding: 0 210px;
    }
    .blog-vic {
        right: 346px;
    }
    .blog-item .blog-inner .blog-inner-text {
        margin-top: 15px;
    }
    .blog-item .blog-inner .blog-inner-text a {
        font-weight: 600;
        font-size: 15px;
        line-height: 144%;
    }
    .skill-text h2 {
        font-size: 37px;
        line-height: 55.2px;
    }
    .official-btn .official-btn-left .f-b-text {
        width: 125px;
    }
    .official-btn .official-btn-right a {
        padding-left: 7px;
        padding-right: 13px;
    }
    .official-btn .official-btn-right {
        width: 95px;
    }
    .Project-two {
        margin: -340px 0 0;
        background-color: #fff;
    }
    .Product {
        padding: 80px 0;
        margin-top: 0;
    }
    .thought-vic {
        right: -15px;
        top: 0;
        z-index: -1;
    }
    .send .send-item .send-inner {
        display: grid;
        gap: 20px;
    }
    .position-img {
        top: -174px !important;
        left: -34px !important;
    }
    .slick .slick-item .slick-text h3 {
        padding-right: 0;
    }
    .inner-service .service-item .service-item-text h3 {
        font-weight: 700;
        font-size: 21px;
    }
    .thought {
        padding: 0 0 120px;
        margin-top: 115px;
    }
    .additional-pr {
        padding: 0 15px;
    }
    .product-details-text h2 {
        font-weight: 600;
        font-size: 38px;
    }
    .product-details-text-right p span {
        padding-left: 2px;
    }
    .about-lawyer .about-img,
    .product-details-text-right-icon {
        margin-left: 0;
    }
    .customers .slick-dots {
        margin: 60px 0 0;
    }
    .customers {
        padding: 115px 0 0;
    }
    .team-item .team-text h3 {
        font-size: 17px;
    }
    .customers .customer-head {
        margin-top: 50px;
    }
    .warren-about {
        margin-top: 40px;
    }
    .tab-item .tab-img .tab-img-overlay .tab-img-overlay-text p::before {
        left: 87px;
    }
    .hero .hero-img .hero-inner-img {
        position: unset;
        top: 66px !important;
        left: 14px;
    }
    .hero .hero-img {
        width: 100%;
        height: 100%;
    }
    .container {
        width: 100%;
        padding: 0 20px;
    }
    .student-img img {
        width: 100% !important;
    }
    .accordion-form .accordion-tittle .accordion-tittle-img {
        right: 20px;
    }
    .accordion-form {
        padding: 0 0 100px;
    }
    .news .row {
        padding: 60px 0 0;
    }
    .about-hero {
        padding: 180px 0 90px;
    }
    .project-4,
    .service-4 {
        padding: 80px 0;
    }
    .copyright {
        margin-top: 80px;
    }
    .footer-four,
    .service-details {
        padding: 80px 0 0;
    }
    .award-heading h2,
    .service-query-content h2 {
        font-size: 45px;
    }
    .service-content p {
        font-size: 16px;
    }
    .service-btn {
        padding-top: 0;
    }
    .about-4 {
        padding: 0 0 30px;
    }
    .service-4 .service-card {
        margin: 0 0 32px;
    }
    .hero .hero-content h1 {
        font-size: 54px;
    }
    .lawyer .hero-content .hero-tittle .hero-tittle-img {
        left: 0;
    }
    .photographer-about {
        padding-top: 100px;
    }
    .lawyer-gallary .lawyear-gallary-gap .lawyergallary-box .lawyergallary-content {
        padding: 19px 0;
        text-align: center;
    }
    .lawyer-service .container {
        z-index: 111111;
        position: relative;
    }
    .counter-content p {
        font-size: 15px;
    }
    .lawyer-service-2 .container {
        padding: 120px 60px 120px 34px;
    }
    .service-card-content h3 {
        font-size: 22px;
    }
    .Project-right-item {
        padding: 0 0 24px;
    }
    .position-absolute {
        position: absolute !important;
    }
    .portfolio-detalis-text {
        display: flex;
        gap: 30px;
        justify-content: start;
    }
    .about-photographer {
        padding: 120px 0 0;
    }
    .photographer-about .about-img .about-inner-img {
        margin-left: 0 !important;
    }
    .team .team-item .team-text h3 {
        font-size: 18px;
    }
    .hero .hero-content .hero-victor-img {
        position: absolute;
        top: 150px;
        left: -8px;
        z-index: -1;
    }
}
@media (max-width: 1440px) {
    .about-two-img img {
        position: unset;
        left: -241px;
        width: 100%;
    }
    .comming-soon-img .comming-soon-img-2 {
        position: absolute;
        bottom: -758px;
        right: 0;
        z-index: -1;
    }
    .about-inner-img img,
    .banner-pos img,
    .hero .hero-img,
    .hero-inner-img img,
    .position-img .about-img img {
        width: 100%;
    }
    .photographer-about .about-img .about-inner-img {
        margin-left: -100px;
    }
    .hero .hero-img .congratulation,
    .hero .hero-img .students {
        display: none;
    }
    .hero .hero-img .hero-inner-img {
        top: 185px;
        left: 0;
    }
    .customers {
        padding: 115px 0 0;
    }
    .slick-dots button {
        margin-bottom: 60px;
    }
    .about-two-img .about-two-img-vic {
        top: 253px;
    }
    .a-i-c {
        align-items: center;
    }
    .contuict-me-main .contact-me-item {
        padding-bottom: 30px;
    }
    .position-img {
        position: absolute;
        top: -283px;
        left: -165px;
    }
    .Project-two {
        margin: -340px 0 0;
    }
}
.about-two-img .about-two-img-vic {
    position: absolute;
    top: 262px;
    right: -26px;
    z-index: -1;
    animation: 8s linear infinite up-down;
}
@media (min-width: 1440px) {
    .banner-pos img {
        width: 65%;
        margin: auto;
    }
    .about-lawyer .about-img {
        margin-left: -113px;
    }
    .about-lawyer .about-img img {
        width: 100%;
    }
}
