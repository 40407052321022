.Product {
    padding: 420px 0 120px;
    background: url(/public/img/Product-bg.png) no-repeat center/cover;
    margin-top: -294px
}

.Product-2 {
    margin-top: 0;
    padding: 124px 0 120px
}

.Product-2 .h-1-blog-btn {
    display: flex;
    justify-content: end;
    margin-top: 25px
}

.Product-2 .pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    gap: 8px
}

.Product-2 .page-link {
    padding: 0;
    height: 40px;
    width: 40px;
    background-color: #fff;
    border: 1px solid #f1f1f1;
    border-radius: 8px;
    text-align: center;
    line-height: 40px
}

.Product-2 .page-link i {
    color: #04091e
}

.Product-2 .page-link {
    color: #747681
}

.Product-2 .page-link:focus {
    z-index: 3;
    color: #0a58ca;
    background-color: #e9ecef;
    outline: 0;
    box-shadow: 0 0 0 .25rem transparent
}

.Product-2 .page-item:first-child .page-link {
    border-radius: 8px
}

.Product-2 .page-item:last-child .page-link {
    border-radius: 8px
}

.Product-head {
    position: relative;
    z-index: 1
}

.Product-head h2 {
    text-align: center;
    font-weight: 700;
    font-size: 48px;
    color: #04091e
}

.Product-vic {
    position: absolute;
    top: 0;
    right: 257px;
    z-index: -1
}

.Product-item {
    position: relative
}

.Product-item .Product-img img {
    width: 100%;
    position: relative
}

.Product-item .Product-img .product-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0%;
    background-color: #2662fa;
    opacity: .9;
    z-index: 111;
    transition: all linear .5s;
    border-radius: 5px
}

.Product-item .Product-img .product-overlay span {
    position: absolute;
    top: 45%;
    left: 45%;
    opacity: 0;
    transition: all linear .5s
}

.Product-item .Product-text {
    position: absolute;
    bottom: 36px;
    left: 30px;
    transition: all linear .5s
}

.Product-item .Product-text p {
    font-weight: 400;
    font-size: 20px;
    color: #fff;
    position: relative;
    z-index: 1;
    margin-left: 20px
}

.Product-item .Product-text p::before {
    position: absolute;
    content: "";
    top: 10px;
    left: -20px;
    width: 14px;
    height: 14px;
    background-color: #daed1a;
    border-radius: 50px;
    z-index: -1
}

.Product-item .Product-text h6 {
    font-weight: 700;
    font-size: 24px;
    color: #fff
}

.Product-item:hover .Product-img .product-overlay {
    height: 100%
}

.Product-item:hover .product-overlay span {
    opacity: 1
}

.Product-item:hover .Product-text {
    opacity: 0
}

.Product-top {
    margin-top: 55px
}

.Product-btn {
    text-align: center;
    margin-top: 60px
}

.Product-btn a {
    font-weight: 600;
    font-size: 18px;
    line-height: 120%;
    display: inline-block;
    color: #747681;
    padding: 16px 24px;
    border: 1.5px solid #747681;
    border-radius: 8px;
    transition: all linear .3s
}

.Product-btn a:hover {
    background-color: #2662fa;
    border: 1.5px solid #2662fa;
    color: #fff
}

.portfolio-details {
    padding: 110px 0 120px
}

.portfolio-details .slick-dots {
    display: flex;
    justify-content: center;
    list-style-type: none;
    gap: 15px;
    margin-top: 60px
}

.portfolio-details .slick-dots button {
    display: block;
    width: 12px;
    height: 12px;
    padding: 0;
    border: none;
    border-radius: 100%;
    background-color: #2662fa;
    opacity: .2;
    text-indent: -9999px
}

.portfolio-details .slick-active button {
    background-color: #2662fa;
    opacity: 1;
    height: 14px;
    width: 14px
}

.portfolio-details-head h2 {
    font-weight: 600;
    font-size: 48px;
    color: #04091e
}


.portfolio-details-text {
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f4f4f4;
    padding-bottom: 6px
}

.portfolio-details-text .portfolio-details-text-item {
    display: flex;
    align-items: center;
    gap: 8px
}

.portfolio-details-text .portfolio-details-text-item .text p {
    font-weight: 500;
    font-size: 20px;
    color: #747681
}

.portfolio-details-middle-text p {
    font-weight: 400;
    font-size: 18px;
    line-height: 152%;
    color: #747681;
    padding: 18px 0 14px
}

.portfolio-details-middle-text span {
    font-weight: 400;
    font-size: 18px;
    line-height: 152%;
    color: #747681
}

.portfolio-details-middle-img {
    /* background: url(../assets/image/others/p-d-middle.png) no-repeat center/cover; */
    padding: 30px 27px 24px 30px;
    margin-top: 14px
}

.portfolio-details-middle-img p {
    font-weight: 400;
    font-size: 18px;
    line-height: 152%;
    color: #747681
}

.portfolio-details-btm {
    padding-top: 14px
}

.portfolio-details-btm p {
    font-weight: 400;
    font-size: 18px;
    line-height: 152%;
    color: #747681
}

.share-img {
    margin-top: 52px
}

.share-btn {
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: center;
    margin-top: 40px;
    border-bottom: 1px solid #f4f4f4;
    padding-bottom: 28px
}

.share-btn .text h3 {
    font-weight: 500;
    font-size: 24px;
    color: #04091e
}

.share-btn .share-icon {
    display: flex;
    gap: 7px
}

.share-btn .share-icon .icon {
    height: 40px;
    width: 40px;
    background-color: #f4f4f4;
    border-radius: 50px;
    text-align: center;
    line-height: 40px;
    transition: all .5s
}

.share-btn .share-icon .icon span svg {
    fill: currentColor;
    color: rgba(38,98,250,.9294117647);
    transition: all .5s
}

.share-btn .share-icon .icon:hover {
    background-color: rgba(38,98,250,.9294117647)
}

.share-btn .share-icon .icon:hover span svg {
    color: #fff
}

.mr-p {
    padding: 0 15px
}

.similar-text h3 {
    font-weight: 600;
    font-size: 24px;
    color: #04091e;
    padding: 50px 0 30px
}

.service {
    padding: 124px 0 120px
}

.service .slick-dots {
    display: flex;
    justify-content: center;
    list-style-type: none;
    margin: 60px 0 0;
    gap: 12px;
    padding: 0
}

.service .slick-dots button {
    display: block;
    width: 12px;
    height: 12px;
    padding: 0;
    border: none;
    border-radius: 100%;
    background-color: #2662fa;
    opacity: .2;
    text-indent: -9999px
}

.service .slick-dots li.slick-active button {
    background-color: #2662fa;
    opacity: 1;
    height: 14px;
    width: 14px
}

.service .slick-center .service-item {
    background-color: #2662fa
}

.service .slick-center .service-item .service-overelay {
    opacity: 1
}

.service .slick-center .service-item .service-icon span svg .path {
    stroke: #fff
}

.service .slick-center .service-item .service-icon span svg .circle {
    color: #fff
}

.service .slick-center .service-item .service-btn a {
    color: #fff
}

.service .slick-center .service-item .service-item-text h3 {
    color: #fff
}

.service .slick-center .service-item .service-item-text p {
    color: #fff
}

.service .slick-center .service-item:hover {
    background-color: #2662fa;
    box-shadow: 0 10px 50px rgba(29,29,29,.05);
    border-radius: 10px
}

.service .slick-center .service-item:hover .service-icon span svg .path {
    stroke: #fff
}

.service .slick-center .service-item:hover .service-icon span svg .circle {
    color: #fff
}

.service .slick-center .service-item:hover .service-item-text h3 {
    color: #fff
}

.service .slick-center .service-item:hover .service-item-text p {
    color: #fff
}

.service .slick-center .service-item:hover .service-btn a {
    color: #fff
}

.service .slick-center .service-item:hover .service-overelay {
    opacity: 1
}

.inner-top {
    margin-top: 40px
}

.m-r {
    padding: 0 15px
}

.inner-mt {
    margin-top: 30px
}

.service-head {
    position: relative;
    z-index: 1
}

.service-head h2 {
    font-weight: 700;
    font-size: 48px;
    line-height: 140%;
    color: #04091e
}

.service-vic {
    position: absolute;
    top: -7px;
    right: 42px;
    z-index: -1
}

.service-text p {
    font-weight: 400;
    font-size: 18px;
    line-height: 160%;
    color: #747681
}

.mar-top {
    margin-top: 60px
}

.service-item {
    padding: 30px 28px;
    border: 1px solid rgba(29,29,29,.05);
    border-radius: 10px;
    transition: background-color .5s ease;
    background-color: #fff;
    position: relative;
    overflow: hidden;
    z-index: 1
}

.service-item .service-overelay {
    position: absolute;
    width: 319px;
    height: 319px;
    border: 80px solid rgba(255,255,255,.0666666667);
    border-radius: 500px;
    bottom: -146px;
    right: -95px;
    z-index: -1;
    opacity: 0;
    transition: .5s ease
}

.service-item .service-icon span {
    color: #2662fa
}

.service-item .service-icon span svg .path {
    stroke: currentColor
}

.service-item .service-icon span svg circle {
    fill: currentColor;
    color: #2662fa
}

.service-item .service-item-text h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
    color: #04091e;
    padding-top: 24px
}

.service-item .service-item-text p {
    font-weight: 400;
    font-size: 18px;
    line-height: 152%;
    color: #747681;
    letter-spacing: -.01em;
    padding-top: 24px
}

.service-item:hover {
    background-color: #2662fa;
    box-shadow: 0 10px 50px rgba(29,29,29,.05);
    border-radius: 10px
}

.service-item:hover .service-icon span svg .path {
    stroke: #fff
}

.service-item:hover .service-icon span svg .circle {
    color: #fff
}

.service-item:hover .service-item-text h3 {
    color: #fff
}

.service-item:hover .service-item-text p {
    color: #fff
}

.service-item:hover .service-btn a {
    color: #fff
}
